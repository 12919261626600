import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  Container,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

const SetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionCode, setActionCode] = useState("");
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    // Get the action code from the URL
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("oobCode");

    if (code) {
      setActionCode(code);
      // Verify the password reset code
      verifyPasswordResetCode(auth, code)
        .then((email) => {
          setEmail(email);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error verifying reset code:", error);
          setError("Invalid or expired password reset link");
          setLoading(false);
        });
    } else {
      setError("No password reset code found");
      setLoading(false);
    }
  }, [location, auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    // Password validation
    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await confirmPasswordReset(auth, actionCode, password);
      setSuccess(true);
      // Redirect to login after 3 seconds
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error("Error setting password:", error);
      setError(error.message || "Error setting password");
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 8, textAlign: "center" }}>
          <Typography>Verifying your link...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: 3, textAlign: "center" }}
            >
              Set Your Password
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}

            {success ? (
              <Alert severity="success" sx={{ mb: 3 }}>
                Password set successfully! Redirecting to login...
              </Alert>
            ) : (
              <Box component="form" onSubmit={handleSubmit}>
                <Typography variant="body2" sx={{ mb: 3 }}>
                  Setting password for: {email}
                </Typography>

                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                  required
                  autoFocus
                />

                <TextField
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  margin="normal"
                  required
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                  size="large"
                >
                  Set Password
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>

        <Box sx={{ mt: 3, textAlign: "center" }}>
          <Typography variant="body2" color="text.secondary">
            After setting your password, you'll be redirected to login.
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default SetPassword;
