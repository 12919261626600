import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Build as BuildIcon,
  VideoLibrary as VideoIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  serverTimestamp,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const priorityOptions = [
  { value: "low", label: "Low", color: "#22c55e" },
  { value: "medium", label: "Medium", color: "#f59e0b" },
  { value: "high", label: "High", color: "#ef4444" },
];

const statusOptions = [
  { value: "pending", label: "Pending", color: "#f59e0b" },
  { value: "in_progress", label: "In Progress", color: "#3b82f6" },
  { value: "completed", label: "Completed", color: "#22c55e" },
];

const Maintenance = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [error, setError] = useState("");
  const [newRequest, setNewRequest] = useState({
    propertyId: "",
    title: "",
    description: "",
    priority: "medium",
    status: "pending",
    unit: "",
  });

  const { organization } = useOrganization();
  const db = getFirestore();
  const queryClient = useQueryClient();

  // Fetch properties for the dropdown
  const { data: properties = [] } = useQuery({
    queryKey: ["properties", organization?.id],
    queryFn: async () => {
      if (!organization?.id) return [];
      const q = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  // Fetch maintenance requests
  const { data: maintenanceRequests = [], isLoading } = useQuery({
    queryKey: ["maintenanceRequests", organization?.id],
    queryFn: async () => {
      if (!organization?.id) return [];
      const q = query(
        collection(db, "maintenanceRequests"),
        where("organizationId", "==", organization.id)
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  const handleAddRequest = async () => {
    try {
      if (
        !newRequest.propertyId ||
        !newRequest.title ||
        !newRequest.description
      ) {
        setError("Please fill in all required fields");
        return;
      }

      const property = properties.find((p) => p.id === newRequest.propertyId);

      const requestData = {
        ...newRequest,
        organizationId: organization.id,
        propertyName: property?.name || "",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      // Add the maintenance request
      const docRef = await addDoc(
        collection(db, "maintenanceRequests"),
        requestData
      );

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "maintenance_request",
        requestId: docRef.id,
        status: "pending",
        organizationId: organization.id,
        timestamp: serverTimestamp(),
        message: `New maintenance request: ${requestData.title}`,
      });

      setOpenDialog(false);
      setNewRequest({
        propertyId: "",
        title: "",
        description: "",
        priority: "medium",
        status: "pending",
        unit: "",
      });
      queryClient.invalidateQueries(["maintenanceRequests"]);
      queryClient.invalidateQueries(["activityLog"]);
    } catch (error) {
      console.error("Error adding maintenance request:", error);
      setError(error.message || "Error adding maintenance request");
    }
  };

  const handleUpdateStatus = async (requestId, newStatus) => {
    try {
      const requestRef = doc(db, "maintenanceRequests", requestId);
      await updateDoc(requestRef, {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      });

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "maintenance_update",
        requestId,
        status: newStatus,
        organizationId: organization.id,
        timestamp: serverTimestamp(),
        message: `Maintenance request status updated to ${newStatus}`,
      });

      queryClient.invalidateQueries(["maintenanceRequests"]);
      queryClient.invalidateQueries(["activityLog"]);
    } catch (error) {
      console.error("Error updating maintenance request:", error);
      setError("Failed to update status");
    }
  };

  const handleViewRequest = (request) => {
    setSelectedRequest(request);
    setViewDialogOpen(true);
  };

  const renderMaintenanceCard = (request) => (
    <Card
      key={request.id}
      sx={{
        mb: 3,
        border: "1px solid",
        borderColor: "divider",
        boxShadow: "none",
        "&:hover": {
          borderColor: "primary.main",
          bgcolor: "background.paper",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: "16px !important",
          "&:last-child": { pb: "16px !important" },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              bgcolor: `${
                priorityOptions.find((p) => p.value === request.priority)?.color
              }15`,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BuildIcon
              sx={{
                color: priorityOptions.find((p) => p.value === request.priority)
                  ?.color,
              }}
            />
          </Box>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {request.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {request.propertyName} {request.unit && `- Unit ${request.unit}`}
            </Typography>
            <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
              <Chip
                label={
                  priorityOptions.find((p) => p.value === request.priority)
                    ?.label
                }
                size="small"
                sx={{
                  bgcolor: `${
                    priorityOptions.find((p) => p.value === request.priority)
                      ?.color
                  }15`,
                  color: priorityOptions.find(
                    (p) => p.value === request.priority
                  )?.color,
                }}
              />
              <Chip
                label={
                  statusOptions.find((s) => s.value === request.status)?.label
                }
                size="small"
                sx={{
                  bgcolor: `${
                    statusOptions.find((s) => s.value === request.status)?.color
                  }15`,
                  color: statusOptions.find((s) => s.value === request.status)
                    ?.color,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Button
            size="small"
            variant="outlined"
            onClick={() => handleViewRequest(request)}
            sx={{ mr: 2 }}
          >
            View
          </Button>
          {statusOptions.map((status) => (
            <Button
              key={status.value}
              size="small"
              variant={
                request.status === status.value ? "contained" : "outlined"
              }
              onClick={() => handleUpdateStatus(request.id, status.value)}
              sx={{
                borderColor: status.color,
                color: request.status === status.value ? "white" : status.color,
                bgcolor:
                  request.status === status.value
                    ? status.color
                    : "transparent",
                "&:hover": {
                  bgcolor:
                    request.status === status.value
                      ? status.color
                      : `${status.color}15`,
                },
              }}
            >
              {status.label}
            </Button>
          ))}
        </Box>
      </CardContent>
    </Card>
  );

  if (isLoading) return null;

  return (
    <Box>
      <DataList
        items={maintenanceRequests}
        renderItem={renderMaintenanceCard}
        searchPlaceholder="Search maintenance requests..."
        searchField="title"
        pageSize={8}
        filters={[
          { label: "All Requests", value: "all" },
          { label: "Pending", value: "pending" },
          { label: "In Progress", value: "in_progress" },
          { label: "Completed", value: "completed" },
        ]}
        actionButtons={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            New Request
          </Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>New Maintenance Request</DialogTitle>
        <DialogContent>
          <TextField
            select
            margin="dense"
            label="Property"
            fullWidth
            required
            value={newRequest.propertyId}
            onChange={(e) =>
              setNewRequest({ ...newRequest, propertyId: e.target.value })
            }
          >
            {properties.map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Unit Number"
            fullWidth
            value={newRequest.unit}
            onChange={(e) =>
              setNewRequest({ ...newRequest, unit: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Title"
            fullWidth
            required
            value={newRequest.title}
            onChange={(e) =>
              setNewRequest({ ...newRequest, title: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            required
            multiline
            rows={3}
            value={newRequest.description}
            onChange={(e) =>
              setNewRequest({ ...newRequest, description: e.target.value })
            }
          />
          <TextField
            select
            margin="dense"
            label="Priority"
            fullWidth
            value={newRequest.priority}
            onChange={(e) =>
              setNewRequest({ ...newRequest, priority: e.target.value })
            }
          >
            {priorityOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddRequest} variant="contained">
            Create Request
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedRequest && (
          <>
            <DialogTitle>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">{selectedRequest.title}</Typography>
                <Chip
                  label={selectedRequest.status.toUpperCase()}
                  color={
                    selectedRequest.status === "completed"
                      ? "success"
                      : selectedRequest.status === "in_progress"
                      ? "warning"
                      : "default"
                  }
                  size="small"
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Property
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedRequest.propertyName}
                    {selectedRequest.unit && ` - Unit ${selectedRequest.unit}`}
                  </Typography>

                  <Typography variant="subtitle2" color="text.secondary">
                    Issue Type
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedRequest.issueType}
                  </Typography>

                  <Typography variant="subtitle2" color="text.secondary">
                    Priority
                  </Typography>
                  <Chip
                    label={selectedRequest.priority.toUpperCase()}
                    color={
                      selectedRequest.priority === "high"
                        ? "error"
                        : selectedRequest.priority === "medium"
                        ? "warning"
                        : "success"
                    }
                    size="small"
                    sx={{ mb: 2 }}
                  />

                  <Typography variant="subtitle2" color="text.secondary">
                    Submitted
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {new Date(selectedRequest.createdAt).toLocaleString()}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Description
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedRequest.description}
                  </Typography>
                </Grid>

                {selectedRequest.mediaFiles &&
                  selectedRequest.mediaFiles.length > 0 && (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        Attachments
                      </Typography>
                      <ImageList cols={3} gap={8}>
                        {selectedRequest.mediaFiles.map((file, index) => (
                          <ImageListItem
                            key={index}
                            sx={{
                              cursor: "pointer",
                              "&:hover": { opacity: 0.8 },
                            }}
                            onClick={() => window.open(file.url, "_blank")}
                          >
                            {file.type === "image" ? (
                              <img
                                src={file.url}
                                alt={`Attachment ${index + 1}`}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "200px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  bgcolor: "grey.100",
                                  borderRadius: 1,
                                }}
                              >
                                <VideoIcon
                                  sx={{ fontSize: 48, color: "primary.main" }}
                                />
                              </Box>
                            )}
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Maintenance;
