import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Login from "./components/Login";
import DashboardLayout from "./components/layout/DashboardLayout";
import Dashboard from "./components/pages/Dashboard";
import Properties from "./components/pages/Properties";
import Maintenance from "./components/pages/Maintenance";
import Tenants from "./components/pages/Tenants";
import Contractors from "./components/pages/Contractors";
import Documents from "./components/pages/Documents";
import { OrganizationProvider } from "./contexts/OrganizationContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import EmailVerification from "./components/EmailVerification";
import Account from "./components/pages/Account";
import TrialCheck from "./components/TrialCheck";
import TenantDashboard from "./components/tenant/TenantDashboard";
import TenantMaintenance from "./components/tenant/TenantMaintenance";
import TenantOnboarding from "./components/tenant/TenantOnboarding";
import TenantDocuments from "./components/tenant/TenantDocuments";
import SetPassword from "./components/tenant/SetPassword";
import TenantPasswordSetup from "./components/tenant/TenantPasswordSetup";
import TenantReview from "./components/pages/TenantReview";
import Billing from "./components/pages/Billing";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // Data will never become stale automatically
      cacheTime: 1000 * 60 * 60 * 24, // Cache for 24 hours
    },
  },
});

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed:", user?.uid);
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <OrganizationProvider>
        <BrowserRouter>
          <TrialCheck />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/verify-email" element={<EmailVerification />} />
            <Route
              path="/dashboard"
              element={
                user ? (
                  <DashboardLayout>
                    <Dashboard />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/dashboard/properties"
              element={
                user ? (
                  <DashboardLayout>
                    <Properties />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/dashboard/maintenance"
              element={
                user ? (
                  <DashboardLayout>
                    <Maintenance />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/dashboard/tenants"
              element={
                user ? (
                  <DashboardLayout>
                    <Tenants />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/dashboard/contractors"
              element={
                user ? (
                  <DashboardLayout>
                    <Contractors />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/dashboard/documents"
              element={
                user ? (
                  <DashboardLayout>
                    <Documents />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route
              path="/dashboard/account"
              element={
                user ? (
                  <DashboardLayout>
                    <Account />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/tenant/set-password" element={<SetPassword />} />
            <Route
              path="/tenant/onboarding"
              element={user ? <TenantOnboarding /> : <Navigate to="/login" />}
            />
            <Route
              path="/tenant/dashboard"
              element={user ? <TenantDashboard /> : <Navigate to="/login" />}
            />
            <Route
              path="/tenant/maintenance"
              element={user ? <TenantMaintenance /> : <Navigate to="/login" />}
            />
            <Route
              path="/tenant/documents"
              element={user ? <TenantDocuments /> : <Navigate to="/login" />}
            />
            <Route
              path="/tenant/setup-password"
              element={<TenantPasswordSetup />}
            />
            <Route
              path="/dashboard/tenants/review/:id"
              element={
                user ? (
                  <DashboardLayout>
                    <TenantReview />
                  </DashboardLayout>
                ) : (
                  <Navigate to="/login" />
                )
              }
            />
            <Route path="/dashboard/billing" element={<Billing />} />
          </Routes>
        </BrowserRouter>
      </OrganizationProvider>
    </QueryClientProvider>
  );
}

export default App;
