import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Box,
  Container,
  Paper,
  Tabs,
  Tab,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Google as GoogleIcon } from "@mui/icons-material";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  Timestamp,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "../contexts/OrganizationContext";
import OrganizationSetup from "./OrganizationSetup";
import { rateLimit } from "../utils/rateLimit";
import ReCAPTCHA from "react-google-recaptcha";
import PricingPlans from "./PricingPlans";

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState("");
  const [showOrgSetup, setShowOrgSetup] = useState(false);
  const [pendingUser, setPendingUser] = useState(null);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    name: "",
    companyName: "",
  });
  const [captchaToken, setCaptchaToken] = useState(null);
  const [resetPasswordDialog, setResetPasswordDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();
  const { setOrganization } = useOrganization();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        formData.email.trim(),
        formData.password
      );

      // First check if user is a tenant
      const tenantQuery = query(
        collection(db, "tenants"),
        where("uid", "==", userCredential.user.uid)
      );
      const tenantSnapshot = await getDocs(tenantQuery);

      if (!tenantSnapshot.empty) {
        // User is a tenant
        const tenantDoc = tenantSnapshot.docs[0];
        const tenantData = tenantDoc.data();

        if (!tenantData.onboardingComplete) {
          // Redirect to onboarding if not complete
          navigate("/tenant/onboarding");
          return;
        } else {
          // Redirect to tenant dashboard
          navigate("/tenant/dashboard");
          return;
        }
      }

      // If not a tenant, proceed with regular user flow
      const userDoc = await getDoc(doc(db, "users", userCredential.user.uid));

      if (!userDoc.exists()) {
        setError("User profile not found. Please contact support.");
        await signOut(auth); // Sign out the user if no profile is found
        setIsSubmitting(false);
        return;
      }

      const userData = userDoc.data();
      if (!userData.organizationId) {
        setError("No organization associated with this account.");
        await signOut(auth);
        setIsSubmitting(false);
        return;
      }

      const orgDoc = await getDoc(
        doc(db, "organizations", userData.organizationId)
      );

      if (!orgDoc.exists()) {
        setError("Organization not found. Please contact support.");
        await signOut(auth);
        setIsSubmitting(false);
        return;
      }

      setOrganization({ id: orgDoc.id, ...orgDoc.data() });
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      switch (error.code) {
        case "auth/invalid-credential":
          setError(
            "Invalid email or password. If you haven't set up your password yet, please check your email for the password setup link."
          );
          break;
        case "auth/user-not-found":
          setError("No account found with this email");
          break;
        case "auth/wrong-password":
          setError("Incorrect password");
          break;
        default:
          setError(error.message || "Failed to login");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      // Check if user already exists
      const userDoc = await getDoc(doc(db, "users", result.user.uid));

      if (!userDoc.exists()) {
        // Show organization setup dialog for new users
        setPendingUser(result.user);
        setShowOrgSetup(true);
        return;
      }

      const orgDoc = await getDoc(
        doc(db, "organizations", userDoc.data().organizationId)
      );
      if (orgDoc.exists()) {
        setOrganization({ id: orgDoc.id, ...orgDoc.data() });
      }

      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitting(true);

    try {
      if (!formData.name || !formData.companyName) {
        setError("Please fill in all required fields");
        return;
      }

      // Create user account
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email.trim(),
        formData.password
      );

      // Create organization
      const orgRef = doc(db, "organizations", userCredential.user.uid);
      await setDoc(orgRef, {
        name: formData.companyName,
        createdAt: new Date().toISOString(),
        ownerId: userCredential.user.uid,
        members: [
          {
            userId: userCredential.user.uid,
            email: formData.email,
            name: formData.name,
            role: "admin",
            joinedAt: new Date().toISOString(),
          },
        ],
      });

      // Create user profile
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: formData.name,
        email: formData.email,
        organizationId: userCredential.user.uid,
        role: "admin",
        createdAt: new Date().toISOString(),
      });

      setOrganization({
        id: userCredential.user.uid,
        name: formData.companyName,
        ownerId: userCredential.user.uid,
      });

      navigate("/dashboard");
    } catch (error) {
      console.error("Registration error:", error);
      setError(error.message || "Failed to register");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleOrgSetupComplete = () => {
    setShowOrgSetup(false);
    setPendingUser(null);
    navigate("/dashboard");
  };

  const handleResetPassword = async () => {
    if (!resetEmail) {
      setError("Please enter your email address");
      return;
    }

    setIsResetting(true);
    try {
      setError("Sending reset email..."); // Show loading state
      await sendPasswordResetEmail(auth, resetEmail, {
        url: `${window.location.origin}/login`, // Redirect URL after password reset
      });
      setResetSuccess(true);
      setError("");
    } catch (error) {
      console.error("Password reset error:", error);
      switch (error.code) {
        case "auth/user-not-found":
          setError("No account found with this email address");
          break;
        case "auth/invalid-email":
          setError("Invalid email address");
          break;
        default:
          setError(error.message || "Failed to send reset email");
      }
    } finally {
      setTimeout(() => {
        setIsResetting(false);
      }, 5000);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            background: "rgba(255, 255, 255, 0.9)",
            backdropFilter: "blur(10px)",
            borderRadius: 4,
            p: 4,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Box sx={{ mb: 4, textAlign: "center" }}>
            <Typography
              component="h1"
              variant="h4"
              sx={{
                fontWeight: 700,
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                backgroundClip: "text",
                color: "transparent",
                mb: 1,
              }}
            >
              {isLogin ? "Welcome Back" : "Create Account"}
            </Typography>
            <Typography color="text.secondary" variant="body2">
              {isLogin
                ? "Sign in to manage your properties"
                : "Get started with property management"}
            </Typography>
          </Box>

          <Tabs
            value={isLogin ? 0 : 1}
            onChange={(_, value) => setIsLogin(!value)}
            sx={{ mb: 3 }}
            centered
          >
            <Tab
              label="Sign In"
              sx={{ textTransform: "none", fontWeight: 500 }}
            />
            <Tab
              label="Register"
              sx={{ textTransform: "none", fontWeight: 500 }}
            />
          </Tabs>

          <Box
            component="form"
            onSubmit={isLogin ? handleLogin : handleRegister}
          >
            {!isLogin && (
              <>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Company Name"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: 2,
                    },
                  }}
                />
              </>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              type="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                },
              }}
            />

            {isLogin && (
              <Box sx={{ width: "100%", textAlign: "right", mt: 1 }}>
                <Button
                  onClick={() => setResetPasswordDialog(true)}
                  sx={{ textTransform: "none" }}
                >
                  Forgot password?
                </Button>
              </Box>
            )}

            {!isLogin && (
              <Box sx={{ my: 2, display: "flex", justifyContent: "center" }}>
                <ReCAPTCHA
                  sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
                  onChange={(token) => setCaptchaToken(token)}
                />
              </Box>
            )}

            {error && (
              <Typography color="error" sx={{ mt: 2, textAlign: "center" }}>
                {error}
              </Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
              sx={{
                mt: 3,
                mb: 2,
                height: 48,
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              {isSubmitting
                ? isLogin
                  ? "Signing In..."
                  : "Creating Account..."
                : isLogin
                ? "Sign In"
                : "Create Account"}
            </Button>

            <Divider sx={{ my: 2 }}>
              <Typography variant="body2" color="text.secondary">
                OR
              </Typography>
            </Divider>

            <Button
              fullWidth
              variant="outlined"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleLogin}
              sx={{
                height: 48,
                borderColor: "divider",
                "&:hover": {
                  borderColor: "primary.main",
                  background: "rgba(37, 99, 235, 0.04)",
                },
              }}
            >
              Continue with Google
            </Button>
          </Box>
        </Paper>
      </Box>

      <OrganizationSetup
        open={showOrgSetup}
        onClose={() => setShowOrgSetup(false)}
        user={pendingUser}
        onComplete={handleOrgSetupComplete}
      />

      <Dialog
        open={resetPasswordDialog}
        onClose={() => {
          setResetPasswordDialog(false);
          setResetSuccess(false);
          setResetEmail("");
          setError("");
          setIsResetting(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          {resetSuccess ? "Check Your Email" : "Reset Password"}
        </DialogTitle>
        <DialogContent>
          {resetSuccess ? (
            <Typography>
              We've sent password reset instructions to your email address.
              Please check your inbox.
            </Typography>
          ) : (
            <>
              <Typography sx={{ mb: 2 }}>
                Enter your email address and we'll send you instructions to
                reset your password.
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                label="Email Address"
                type="email"
                fullWidth
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setResetPasswordDialog(false);
              setResetSuccess(false);
              setResetEmail("");
              setError("");
              setIsResetting(false);
            }}
          >
            {resetSuccess ? "Close" : "Cancel"}
          </Button>
          {!resetSuccess && (
            <Button
              onClick={handleResetPassword}
              variant="contained"
              disabled={isResetting}
            >
              {isResetting ? "Sending..." : "Reset Password"}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;
