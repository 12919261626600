import React, { useEffect } from "react";
import { Box, Typography, Paper, Container, Button } from "@mui/material";
import { MailOutline } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const EmailVerification = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if we were redirected here after email verification
    const params = new URLSearchParams(window.location.search);
    if (params.get("continueUrl")) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          mt: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            background: "rgba(255, 255, 255, 0.9)",
            backdropFilter: "blur(10px)",
            borderRadius: 4,
            p: 4,
            border: "1px solid",
            borderColor: "divider",
            textAlign: "center",
          }}
        >
          <MailOutline sx={{ fontSize: 48, color: "primary.main", mb: 2 }} />
          <Typography variant="h5" component="h1" gutterBottom>
            Verify your email
          </Typography>
          <Typography color="text.secondary">
            We've sent a verification email to your inbox. Please click the link
            in the email to verify your account.
          </Typography>
          <Typography sx={{ mt: 2 }} color="text.secondary">
            If you don't see the email, check your spam folder.
          </Typography>
          <Button
            variant="outlined"
            sx={{ mt: 3 }}
            onClick={() => navigate("/login")}
          >
            Back to Login
          </Button>
        </Paper>
      </Box>
    </Container>
  );
};

export default EmailVerification;
