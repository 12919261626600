import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Divider,
  Alert,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  PersonAdd as PersonAddIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  doc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  setDoc,
} from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { useOrganization } from "../../contexts/OrganizationContext";

const Account = () => {
  const [editing, setEditing] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    password: "",
    role: "user",
  });

  const { organization, setOrganization } = useOrganization();
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    if (organization) {
      setOrgName(organization.name);
      fetchUsers();
    }
  }, [organization]);

  const fetchUsers = async () => {
    try {
      const q = query(
        collection(db, "users"),
        where("organizationId", "==", organization.id)
      );
      const snapshot = await getDocs(q);
      const usersList = [];
      snapshot.forEach((doc) => {
        usersList.push({ id: doc.id, ...doc.data() });
      });
      setUsers(usersList);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError("Failed to load users");
    }
  };

  const handleUpdateOrgName = async () => {
    try {
      await updateDoc(doc(db, "organizations", organization.id), {
        name: orgName,
      });
      setOrganization({ ...organization, name: orgName });
      setEditing(false);
      setSuccess("Organization name updated successfully");
    } catch (error) {
      console.error("Error updating organization name:", error);
      setError("Failed to update organization name");
    }
  };

  const handleAddUser = async () => {
    try {
      if (!newUser.name || !newUser.email || !newUser.password) {
        setError("Please fill in all required fields");
        return;
      }

      // Create Firebase auth user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password
      );

      // Create user document in the users collection
      await setDoc(doc(db, "users", userCredential.user.uid), {
        name: newUser.name,
        email: newUser.email,
        organizationId: organization.id,
        role: newUser.role,
        createdAt: new Date().toISOString(),
      });

      setOpenDialog(false);
      setNewUser({
        name: "",
        email: "",
        password: "",
        role: "user",
      });
      fetchUsers();
      setSuccess("User added successfully");
    } catch (error) {
      console.error("Error adding user:", error);
      setError(error.message || "Error adding user");
    }
  };

  const handleDeleteUser = async (userId) => {
    if (window.confirm("Are you sure you want to remove this user?")) {
      try {
        await deleteDoc(doc(db, "users", userId));
        fetchUsers();
        setSuccess("User removed successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
        setError("Failed to remove user");
      }
    }
  };

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", py: 4, px: 3 }}>
      <Typography variant="h4" sx={{ mb: 4, fontWeight: 700 }}>
        Account Settings
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" sx={{ mb: 3 }}>
          {success}
        </Alert>
      )}

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
            Organization Details
          </Typography>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            {editing ? (
              <>
                <TextField
                  fullWidth
                  label="Organization Name"
                  value={orgName}
                  onChange={(e) => setOrgName(e.target.value)}
                />
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleUpdateOrgName}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1">{organization?.name}</Typography>
                <IconButton
                  size="small"
                  onClick={() => setEditing(true)}
                  sx={{ color: "primary.main" }}
                >
                  <EditIcon />
                </IconButton>
              </>
            )}
          </Box>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Users
            </Typography>
            <Button
              variant="contained"
              startIcon={<PersonAddIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Add User
            </Button>
          </Box>

          <List>
            {users.map((user, index) => (
              <React.Fragment key={user.id}>
                <ListItem>
                  <Avatar sx={{ mr: 2 }}>{user.name?.[0]}</Avatar>
                  <ListItemText
                    primary={user.name}
                    secondary={
                      <>
                        {user.email}
                        <br />
                        Role: {user.role}
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={() => handleDeleteUser(user.id)}
                      sx={{ color: "error.main" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < users.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </CardContent>
      </Card>

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            required
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            required
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            required
            value={newUser.password}
            onChange={(e) =>
              setNewUser({ ...newUser, password: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddUser} variant="contained">
            Add User
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Account;
