import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Grid,
  Autocomplete,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Engineering as EngineeringIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";

const specialtyOptions = [
  "Plumbing",
  "Electrical",
  "HVAC",
  "Carpentry",
  "Painting",
  "Landscaping",
  "General Maintenance",
  "Roofing",
  "Cleaning",
  "Pest Control",
];

const Contractors = () => {
  const [contractors, setContractors] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newContractor, setNewContractor] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    specialties: [],
    rate: "",
    notes: "",
  });

  const { organization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    if (organization?.id) {
      fetchContractors();
    }
  }, [organization]);

  const fetchContractors = async () => {
    try {
      const q = query(
        collection(db, "contractors"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const contractorsList = [];
      querySnapshot.forEach((doc) => {
        contractorsList.push({ id: doc.id, ...doc.data() });
      });
      setContractors(contractorsList);
    } catch (error) {
      console.error("Error fetching contractors:", error);
      setError("Failed to load contractors");
    } finally {
      setLoading(false);
    }
  };

  const handleAddContractor = async () => {
    try {
      if (
        !newContractor.name ||
        !newContractor.email ||
        !newContractor.specialties.length
      ) {
        setError("Please fill in all required fields");
        return;
      }

      await addDoc(collection(db, "contractors"), {
        ...newContractor,
        organizationId: organization.id,
        createdAt: new Date().toISOString(),
        rate: parseFloat(newContractor.rate) || 0,
      });

      setOpenDialog(false);
      setNewContractor({
        name: "",
        company: "",
        email: "",
        phone: "",
        specialties: [],
        rate: "",
        notes: "",
      });
      fetchContractors();
    } catch (error) {
      console.error("Error adding contractor:", error);
      setError(error.message || "Error adding contractor");
    }
  };

  const handleDeleteContractor = async (contractorId) => {
    if (window.confirm("Are you sure you want to delete this contractor?")) {
      try {
        await deleteDoc(doc(db, "contractors", contractorId));
        fetchContractors();
      } catch (error) {
        console.error("Error deleting contractor:", error);
        setError("Failed to delete contractor");
      }
    }
  };

  const renderContractorCard = (contractor) => (
    <Card
      key={contractor.id}
      sx={{
        mb: 2,
        border: "1px solid",
        borderColor: "divider",
        boxShadow: "none",
        "&:hover": {
          borderColor: "primary.main",
          bgcolor: "background.paper",
        },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "&:last-child": { pb: 2 },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Avatar
            sx={{
              bgcolor: "primary.lighter",
              color: "primary.main",
              width: 48,
              height: 48,
            }}
          >
            {contractor.name?.[0]}
          </Avatar>
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              {contractor.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {contractor.company}
            </Typography>
            <Box sx={{ mt: 1, display: "flex", gap: 1, flexWrap: "wrap" }}>
              {contractor.specialties.map((specialty) => (
                <Chip
                  key={specialty}
                  label={specialty}
                  size="small"
                  sx={{ bgcolor: "primary.lighter", color: "primary.main" }}
                />
              ))}
              {contractor.rate && (
                <Chip
                  label={`$${contractor.rate}/hr`}
                  size="small"
                  sx={{ bgcolor: "success.lighter", color: "success.main" }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            size="small"
            sx={{ color: "primary.main" }}
            onClick={() => handleEdit(contractor)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            size="small"
            sx={{ color: "error.main" }}
            onClick={() => handleDeleteContractor(contractor.id)}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );

  if (loading) return null;

  return (
    <Box>
      <DataList
        items={contractors}
        renderItem={renderContractorCard}
        searchPlaceholder="Search contractors..."
        searchField="name"
        pageSize={8}
        filters={[
          { label: "All Specialties", value: "all" },
          { label: "Plumbing", value: "plumbing" },
          { label: "Electrical", value: "electrical" },
          { label: "HVAC", value: "hvac" },
        ]}
        actionButtons={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Add Contractor
          </Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Contractor</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Name"
                fullWidth
                required
                value={newContractor.name}
                onChange={(e) =>
                  setNewContractor({ ...newContractor, name: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Company"
                fullWidth
                value={newContractor.company}
                onChange={(e) =>
                  setNewContractor({
                    ...newContractor,
                    company: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                required
                value={newContractor.email}
                onChange={(e) =>
                  setNewContractor({ ...newContractor, email: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Phone"
                fullWidth
                value={newContractor.phone}
                onChange={(e) =>
                  setNewContractor({ ...newContractor, phone: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={specialtyOptions}
                value={newContractor.specialties}
                onChange={(_, newValue) =>
                  setNewContractor({ ...newContractor, specialties: newValue })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Specialties"
                    required
                    margin="dense"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Hourly Rate"
                type="number"
                fullWidth
                value={newContractor.rate}
                onChange={(e) =>
                  setNewContractor({ ...newContractor, rate: e.target.value })
                }
                InputProps={{
                  startAdornment: "$",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                multiline
                rows={3}
                value={newContractor.notes}
                onChange={(e) =>
                  setNewContractor({ ...newContractor, notes: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddContractor} variant="contained">
            Add Contractor
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Contractors;
