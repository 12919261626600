import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { initializeApp } from "firebase/app";
import "./index.css";
import App from "./App.jsx";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAs9C6aiDUBYeoiGeY7n3sFlyf686gh8SE",
  authDomain: "tenentio.firebaseapp.com",
  projectId: "tenentio",
  storageBucket: "tenentio.firebasestorage.app",
  messagingSenderId: "1071224466607",
  appId: "1:1071224466607:web:80f1831d1a0d5155ee385a",
  measurementId: "G-HL4TQ1F2EQ",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const theme = createTheme({
  palette: {
    primary: {
      main: "#2563eb",
    },
    secondary: {
      main: "#7c3aed",
    },
    background: {
      default: "#f8fafc",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StrictMode>
);
