import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Upload as UploadIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
  Description as DocumentIcon,
  Folder as FolderIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getAuth } from "firebase/auth";

const documentCategories = [
  "Lease Agreements",
  "Property Documents",
  "Tenant Documents",
  "Maintenance Records",
  "Financial Records",
  "Legal Documents",
  "Insurance Documents",
  "Templates",
  "Other",
];

const Documents = () => {
  const [documents, setDocuments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [newDocument, setNewDocument] = useState({
    title: "",
    category: "",
    description: "",
    file: null,
  });

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    fetchDocuments();
  }, [auth.currentUser]);

  const fetchDocuments = async () => {
    if (!auth.currentUser) return;

    try {
      const q = query(
        collection(db, "documents"),
        where("ownerId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const documentsList = [];
      querySnapshot.forEach((doc) => {
        documentsList.push({ id: doc.id, ...doc.data() });
      });
      setDocuments(documentsList);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewDocument({ ...newDocument, file });
    }
  };

  const handleUploadDocument = async () => {
    if (!newDocument.file) return;

    try {
      const timestamp = new Date().getTime();
      const fileName = `${auth.currentUser.uid}/${newDocument.category}/${timestamp}_${newDocument.file.name}`;
      const storageRef = ref(storage, fileName);

      const uploadTask = uploadBytesResumable(storageRef, newDocument.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Error uploading file:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(storageRef);
          await addDoc(collection(db, "documents"), {
            title: newDocument.title,
            category: newDocument.category,
            description: newDocument.description,
            fileName: newDocument.file.name,
            fileUrl: downloadURL,
            filePath: fileName,
            uploadDate: new Date().toISOString(),
            ownerId: auth.currentUser.uid,
            size: newDocument.file.size,
            type: newDocument.file.type,
          });

          setOpenDialog(false);
          setNewDocument({
            title: "",
            category: "",
            description: "",
            file: null,
          });
          setUploadProgress(0);
          fetchDocuments();
        }
      );
    } catch (error) {
      console.error("Error uploading document:", error);
    }
  };

  const handleDeleteDocument = async (document) => {
    try {
      // Delete from Storage
      const storageRef = ref(storage, document.filePath);
      await deleteObject(storageRef);

      // Delete from Firestore
      await deleteDoc(doc(db, "documents", document.id));

      fetchDocuments();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setOpenDialog(true)}
          sx={{
            background: "linear-gradient(45deg, #2563eb, #7c3aed)",
            "&:hover": {
              background: "linear-gradient(45deg, #1e40af, #5b21b6)",
            },
          }}
        >
          Upload Document
        </Button>
      </Box>

      <Grid container spacing={3}>
        {documentCategories.map((category) => {
          const categoryDocuments = documents.filter(
            (doc) => doc.category === category
          );
          if (categoryDocuments.length === 0) return null;

          return (
            <Grid item xs={12} key={category}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                {category}
              </Typography>
              <Grid container spacing={2}>
                {categoryDocuments.map((document) => (
                  <Grid item xs={12} sm={6} md={4} key={document.id}>
                    <Card
                      sx={{
                        height: "100%",
                        "&:hover": {
                          transform: "translateY(-4px)",
                          transition: "transform 0.2s ease-in-out",
                        },
                      }}
                    >
                      <CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                            mb: 2,
                          }}
                        >
                          <DocumentIcon
                            sx={{ color: "primary.main", fontSize: 40 }}
                          />
                          <Box>
                            <Typography variant="h6">
                              {document.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {formatFileSize(document.size)}
                            </Typography>
                          </Box>
                        </Box>

                        {document.description && (
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 2 }}
                          >
                            {document.description}
                          </Typography>
                        )}

                        <Typography variant="body2" color="text.secondary">
                          Uploaded:{" "}
                          {new Date(document.uploadDate).toLocaleDateString()}
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "flex-end", p: 2 }}>
                        <Tooltip title="Download">
                          <IconButton
                            size="small"
                            onClick={() => window.open(document.fileUrl)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Share">
                          <IconButton size="small">
                            <ShareIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton
                            size="small"
                            onClick={() => handleDeleteDocument(document)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      {/* Upload Document Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Document Title"
            fullWidth
            value={newDocument.title}
            onChange={(e) =>
              setNewDocument({ ...newDocument, title: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Category</InputLabel>
            <Select
              value={newDocument.category}
              label="Category"
              onChange={(e) =>
                setNewDocument({ ...newDocument, category: e.target.value })
              }
            >
              {documentCategories.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={newDocument.description}
            onChange={(e) =>
              setNewDocument({ ...newDocument, description: e.target.value })
            }
          />
          <Button
            component="label"
            variant="outlined"
            startIcon={<UploadIcon />}
            sx={{ mt: 2 }}
            fullWidth
          >
            Select File
            <input
              type="file"
              hidden
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
            />
          </Button>
          {newDocument.file && (
            <Typography variant="body2" sx={{ mt: 1 }}>
              Selected: {newDocument.file.name}
            </Typography>
          )}
          {uploadProgress > 0 && uploadProgress < 100 && (
            <Box sx={{ mt: 2 }}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button
            onClick={handleUploadDocument}
            variant="contained"
            disabled={
              !newDocument.file || !newDocument.title || !newDocument.category
            }
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Documents;
