import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
} from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";

const plans = [
  {
    name: "Basic",
    price: "£25",
    features: [
      "2 users",
      "5 properties",
      "5 tenants",
      "2 contractors",
      "5 documents",
    ],
    limits: {
      users: 2,
      properties: 5,
      tenants: 5,
      contractors: 2,
      documents: 5,
    },
  },
  {
    name: "Pro",
    price: "£60",
    features: [
      "10 users",
      "50 properties",
      "100 tenants",
      "20 contractors",
      "20 documents",
    ],
    limits: {
      users: 10,
      properties: 50,
      tenants: 100,
      contractors: 20,
      documents: 20,
    },
  },
  {
    name: "Enterprise",
    price: "Contact Sales",
    features: [
      "20 users",
      "200 properties",
      "500 tenants",
      "100 contractors",
      "100 documents",
    ],
    limits: {
      users: 20,
      properties: 200,
      tenants: 500,
      contractors: 100,
      documents: 100,
    },
  },
];

const PricingPlans = ({ onPlanSelect }) => {
  const { organization } = useOrganization();
  const currentPlan = organization?.plan?.name?.toLowerCase() || "";

  const handlePlanSelect = (plan) => {
    if (plan.name === "Enterprise") {
      // Could add contact sales functionality here later
      return;
    }

    const planData = {
      name: plan.name,
      limits: plan.limits,
      status: "active",
      startDate: new Date().toISOString(),
    };

    onPlanSelect(planData);
  };

  return (
    <Box sx={{ py: 3 }}>
      <Grid container spacing={3} justifyContent="center">
        {plans.map((plan) => {
          const isCurrentPlan = currentPlan === plan.name.toLowerCase();
          const isEnterprise = plan.name === "Enterprise";

          return (
            <Grid item xs={12} md={4} key={plan.name}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  ...(isCurrentPlan && {
                    border: 2,
                    borderColor: "primary.main",
                  }),
                  ...(isEnterprise && {
                    opacity: 0.7,
                  }),
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography variant="h5" component="div" gutterBottom>
                    {plan.name}
                  </Typography>
                  <Typography
                    variant="h4"
                    component="div"
                    gutterBottom
                    sx={{ fontWeight: "bold" }}
                  >
                    {plan.price}
                  </Typography>
                  <List>
                    {plan.features.map((feature, index) => (
                      <ListItem key={index} sx={{ py: 0.5 }}>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                          <CheckIcon color="primary" fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
                <Box sx={{ p: 2, pt: 0 }}>
                  <Button
                    fullWidth
                    variant={isCurrentPlan ? "outlined" : "contained"}
                    onClick={() => handlePlanSelect(plan)}
                    disabled={isEnterprise || isCurrentPlan}
                    sx={{
                      ...(!isEnterprise &&
                        !isCurrentPlan && {
                          background:
                            "linear-gradient(45deg, #2563eb, #7c3aed)",
                          "&:hover": {
                            background:
                              "linear-gradient(45deg, #1e40af, #5b21b6)",
                          },
                        }),
                    }}
                  >
                    {isCurrentPlan
                      ? "Current Plan"
                      : isEnterprise
                      ? "Contact Sales"
                      : "Select Plan"}
                  </Button>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PricingPlans;
