import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Chip,
  Avatar,
  Alert,
} from "@mui/material";
import {
  Build as BuildIcon,
  Description as DocumentIcon,
} from "@mui/icons-material";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import TenantLayout from "./TenantLayout";

const TenantDashboard = () => {
  const [tenantData, setTenantData] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchTenantData = async () => {
      if (auth.currentUser) {
        try {
          const tenantDoc = await getDoc(
            doc(db, "tenants", auth.currentUser.uid)
          );
          if (tenantDoc.exists()) {
            setTenantData(tenantDoc.data());
          }
        } catch (error) {
          console.error("Error fetching tenant data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchTenantData();
  }, [auth.currentUser]);

  if (loading) return null;

  if (tenantData?.status === "pending_approval") {
    return (
      <TenantLayout>
        <Box sx={{ maxWidth: 600, mx: "auto", p: 3 }}>
          <Alert
            severity="info"
            sx={{
              mb: 3,
              "& .MuiAlert-message": {
                width: "100%",
              },
            }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              Application Under Review
            </Typography>
            <Typography>
              Your tenant application is currently being reviewed. We'll notify
              you once it's approved.
            </Typography>
          </Alert>

          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2 }}>
                What's Next?
              </Typography>
              <Typography variant="body2" paragraph>
                • Our team is reviewing your submitted documents
              </Typography>
              <Typography variant="body2" paragraph>
                • We may contact you for additional information if needed
              </Typography>
              <Typography variant="body2">
                • Once approved, you'll have full access to all tenant features
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </TenantLayout>
    );
  }

  return (
    <TenantLayout>
      <Box>
        <Typography variant="h4" sx={{ mb: 4, fontWeight: 700 }}>
          Welcome, {tenantData?.firstName}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                  Property Details
                </Typography>
                <Typography variant="body1">
                  {tenantData?.propertyName}
                  {tenantData?.unit && ` - Unit ${tenantData.unit}`}
                </Typography>
                <Box sx={{ mt: 2 }}>
                  <Chip
                    label={`Rent: $${tenantData?.rentAmount?.toLocaleString()}/month`}
                    color="primary"
                    size="small"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label={`Lease ends: ${new Date(
                      tenantData?.leaseEnd
                    ).toLocaleDateString()}`}
                    size="small"
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar sx={{ bgcolor: "warning.main" }}>
                    <BuildIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="h6">Maintenance Requests</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Submit and track maintenance issues
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Box display="flex" alignItems="center" gap={2}>
                  <Avatar sx={{ bgcolor: "info.main" }}>
                    <DocumentIcon />
                  </Avatar>
                  <Box>
                    <Typography variant="h6">Documents</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Access lease and other important documents
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </TenantLayout>
  );
};

export default TenantDashboard;
