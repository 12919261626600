import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Grid,
  Paper,
  Alert,
  IconButton,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import FileUpload from "../common/FileUpload";

const steps = [
  "Personal Information",
  "Employment History",
  "Address History",
  "Documents Upload",
];

const TenantOnboarding = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    personalInfo: {
      dateOfBirth: "",
      nationalInsurance: "",
      currentIncome: "",
    },
    employmentHistory: [
      {
        employer: "",
        position: "",
        startDate: "",
        endDate: "",
        contactPerson: "",
        contactPhone: "",
      },
    ],
    addressHistory: [
      {
        address: "",
        city: "",
        postcode: "",
        startDate: "",
        endDate: "",
        landlordName: "",
        landlordPhone: "",
      },
    ],
    documents: [],
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const checkOnboarding = async () => {
      if (auth.currentUser) {
        try {
          const tenantRef = doc(db, "tenants", auth.currentUser.uid);
          const tenantDoc = await getDoc(tenantRef);

          if (!tenantDoc.exists()) {
            await setDoc(tenantRef, {
              uid: auth.currentUser.uid,
              email: auth.currentUser.email,
              createdAt: new Date().toISOString(),
              status: "pending_onboarding",
              onboardingComplete: false,
              approved: false,
            });
            setLoading(false);
          } else if (tenantDoc.data().onboardingComplete && !showSuccess) {
            navigate("/tenant/dashboard");
          } else {
            setLoading(false);
          }
        } catch (error) {
          console.error("Error checking onboarding status:", error);
          setError("Failed to check onboarding status");
          setLoading(false);
        }
      }
    };

    checkOnboarding();
  }, [auth.currentUser, db, navigate, showSuccess]);

  const handleNext = async () => {
    if (activeStep === steps.length - 1) {
      try {
        const tenantRef = doc(db, "tenants", auth.currentUser.uid);
        await setDoc(
          tenantRef,
          {
            ...formData,
            onboardingComplete: true,
            status: "pending_approval",
            updatedAt: new Date().toISOString(),
          },
          { merge: true }
        );
        setShowSuccess(true);
      } catch (error) {
        console.error("Error saving onboarding data:", error);
        setError("Failed to save onboarding data");
      }
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  const addEmploymentHistory = () => {
    setFormData({
      ...formData,
      employmentHistory: [
        ...formData.employmentHistory,
        {
          employer: "",
          position: "",
          startDate: "",
          endDate: "",
          contactPerson: "",
          contactPhone: "",
        },
      ],
    });
  };

  const addAddressHistory = () => {
    setFormData({
      ...formData,
      addressHistory: [
        ...formData.addressHistory,
        {
          address: "",
          city: "",
          postcode: "",
          startDate: "",
          endDate: "",
          landlordName: "",
          landlordPhone: "",
        },
      ],
    });
  };

  const handleEmploymentChange = (index, field, value) => {
    const newHistory = [...formData.employmentHistory];
    newHistory[index] = { ...newHistory[index], [field]: value };
    setFormData({ ...formData, employmentHistory: newHistory });
  };

  const handleAddressChange = (index, field, value) => {
    const newHistory = [...formData.addressHistory];
    newHistory[index] = { ...newHistory[index], [field]: value };
    setFormData({ ...formData, addressHistory: newHistory });
  };

  const removeEmploymentHistory = (index) => {
    const newHistory = formData.employmentHistory.filter((_, i) => i !== index);
    setFormData({ ...formData, employmentHistory: newHistory });
  };

  const removeAddressHistory = (index) => {
    const newHistory = formData.addressHistory.filter((_, i) => i !== index);
    setFormData({ ...formData, addressHistory: newHistory });
  };

  const handleFileUpload = (uploadedFiles) => {
    setFormData({
      ...formData,
      documents: [
        ...formData.documents,
        ...uploadedFiles.map((file) => ({
          name: file.name,
          url: file.url,
          type: file.type,
          size: file.size,
          uploadedAt: file.uploadedAt,
        })),
      ],
    });
  };

  if (loading) {
    return (
      <Box sx={{ mt: 8, textAlign: "center" }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 8, textAlign: "center" }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (showSuccess) {
    return (
      <Box
        sx={{
          width: "100%",
          maxWidth: "600px",
          mx: "auto",
          p: { xs: 2, sm: 3 },
          textAlign: "center",
        }}
      >
        <Paper
          sx={{
            p: { xs: 3, sm: 4 },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography
            variant="h5"
            component="h1"
            sx={{ fontWeight: "500", color: "success.main" }}
          >
            Application Submitted Successfully!
          </Typography>

          <Alert severity="success" sx={{ width: "100%" }}>
            Thank you for completing your profile. We will review your
            application and contact you shortly.
          </Alert>

          <Typography variant="body1" color="text.secondary">
            Your application is now under review. Once approved, you'll have
            full access to your tenant portal.
          </Typography>

          <Button
            variant="contained"
            onClick={() => navigate("/tenant/dashboard")}
            sx={{ mt: 2 }}
          >
            Go to Dashboard
          </Button>
        </Paper>
      </Box>
    );
  }

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Date of Birth"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={formData.personalInfo.dateOfBirth}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    personalInfo: {
                      ...formData.personalInfo,
                      dateOfBirth: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="National Insurance Number"
                value={formData.personalInfo.nationalInsurance}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    personalInfo: {
                      ...formData.personalInfo,
                      nationalInsurance: e.target.value,
                    },
                  })
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Current Annual Income"
                type="number"
                value={formData.personalInfo.currentIncome}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    personalInfo: {
                      ...formData.personalInfo,
                      currentIncome: e.target.value,
                    },
                  })
                }
              />
            </Grid>
          </Grid>
        );

      case 1:
        return (
          <Box>
            {formData.employmentHistory.map((job, index) => (
              <Grid container spacing={2} key={index} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Employment {index + 1}</Typography>
                    {index > 0 && (
                      <IconButton
                        onClick={() => removeEmploymentHistory(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Employer"
                    value={job.employer}
                    onChange={(e) =>
                      handleEmploymentChange(index, "employer", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Position"
                    value={job.position}
                    onChange={(e) =>
                      handleEmploymentChange(index, "position", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={job.startDate}
                    onChange={(e) =>
                      handleEmploymentChange(index, "startDate", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="End Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={job.endDate}
                    onChange={(e) =>
                      handleEmploymentChange(index, "endDate", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Person"
                    value={job.contactPerson}
                    onChange={(e) =>
                      handleEmploymentChange(
                        index,
                        "contactPerson",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Contact Phone"
                    value={job.contactPhone}
                    onChange={(e) =>
                      handleEmploymentChange(
                        index,
                        "contactPhone",
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addEmploymentHistory}
              variant="outlined"
              fullWidth
            >
              Add Employment History
            </Button>
          </Box>
        );

      case 2:
        return (
          <Box>
            {formData.addressHistory.map((address, index) => (
              <Grid container spacing={2} key={index} sx={{ mb: 3 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6">Address {index + 1}</Typography>
                    {index > 0 && (
                      <IconButton
                        onClick={() => removeAddressHistory(index)}
                        color="error"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    value={address.address}
                    onChange={(e) =>
                      handleAddressChange(index, "address", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="City"
                    value={address.city}
                    onChange={(e) =>
                      handleAddressChange(index, "city", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Postcode"
                    value={address.postcode}
                    onChange={(e) =>
                      handleAddressChange(index, "postcode", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={address.startDate}
                    onChange={(e) =>
                      handleAddressChange(index, "startDate", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="End Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={address.endDate}
                    onChange={(e) =>
                      handleAddressChange(index, "endDate", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Landlord Name"
                    value={address.landlordName}
                    onChange={(e) =>
                      handleAddressChange(index, "landlordName", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Landlord Phone"
                    value={address.landlordPhone}
                    onChange={(e) =>
                      handleAddressChange(
                        index,
                        "landlordPhone",
                        e.target.value
                      )
                    }
                  />
                </Grid>
              </Grid>
            ))}
            <Button
              startIcon={<AddIcon />}
              onClick={addAddressHistory}
              variant="outlined"
              fullWidth
            >
              Add Address History
            </Button>
          </Box>
        );

      case 3:
        return (
          <Box sx={{ width: "100%", maxWidth: "600px", mx: "auto" }}>
            <Typography
              variant="body1"
              sx={{
                mb: 3,
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Please upload the following documents:
            </Typography>
            <Box
              sx={{
                mb: 3,
                px: { xs: 2, sm: 4 },
                "& ul": {
                  listStyle: "none",
                  padding: 0,
                  margin: 0,
                },
                "& li": {
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                  "&::before": {
                    content: '"•"',
                    color: "primary.main",
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                    width: "20px",
                    display: "inline-block",
                  },
                },
              }}
            >
              <ul>
                <li>Proof of ID (Passport or Driving License)</li>
                <li>Proof of Address (Utility Bill or Bank Statement)</li>
                <li>Employment Contract or Proof of Income</li>
              </ul>
            </Box>

            <Box sx={{ px: { xs: 2, sm: 4 } }}>
              <FileUpload
                onUpload={handleFileUpload}
                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
                multiple
              />

              {formData.documents.length > 0 && (
                <Box
                  sx={{
                    mt: 3,
                    p: 2,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                    boxShadow: 1,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mb: 1,
                      fontWeight: "500",
                    }}
                  >
                    Uploaded Documents:
                  </Typography>
                  <ul style={{ margin: 0, paddingLeft: "20px" }}>
                    {formData.documents.map((doc, index) => (
                      <li key={index}>
                        <a
                          href={doc.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "inherit", textDecoration: "none" }}
                        >
                          {doc.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "800px",
        mx: "auto",
        p: { xs: 2, sm: 3 }, // Smaller padding on mobile
      }}
    >
      <Paper
        sx={{
          p: { xs: 2, sm: 3 }, // Smaller padding on mobile
          borderRadius: { xs: 0, sm: 1 }, // No border radius on mobile
        }}
      >
        <Typography
          variant="h5"
          component="h1"
          sx={{
            mb: { xs: 3, sm: 4 },
            textAlign: "center",
            fontSize: { xs: "1.25rem", sm: "1.5rem" }, // Smaller font on mobile
          }}
        >
          Complete Your Profile
        </Typography>

        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            mb: { xs: 3, sm: 4 },
            "& .MuiStepLabel-label": {
              fontSize: { xs: "0.875rem", sm: "1rem" }, // Smaller font on mobile
            },
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ mt: { xs: 3, sm: 4 } }}>{renderStepContent(activeStep)}</Box>

        <Box
          sx={{
            mt: { xs: 3, sm: 4 },
            display: "flex",
            justifyContent: "space-between",
            gap: 2, // Add gap between buttons
          }}
        >
          <Button
            disabled={activeStep === 0}
            onClick={() => setActiveStep((prev) => prev - 1)}
            sx={{ flex: { xs: 1, sm: 0 } }} // Full width on mobile
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{ flex: { xs: 1, sm: 0 } }} // Full width on mobile
          >
            {activeStep === steps.length - 1 ? "Submit" : "Next"}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default TenantOnboarding;
