import React, { useState, useEffect } from "react";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";
import PricingPlans from "./PricingPlans";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const FirstTimeLogin = () => {
  const [open, setOpen] = useState(false);
  const { organization, setOrganization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    const checkFirstTimeLogin = async () => {
      if (organization?.id) {
        const orgDoc = await getDoc(doc(db, "organizations", organization.id));
        const orgData = orgDoc.data();

        if (!orgData.plan) {
          setOpen(true);
        }
      }
    };

    checkFirstTimeLogin();
  }, [organization]);

  const handlePlanSelect = async (planData) => {
    try {
      await updateDoc(doc(db, "organizations", organization.id), {
        plan: planData,
      });

      // Update local organization state
      setOrganization((prev) => ({
        ...prev,
        plan: planData,
      }));

      setOpen(false);
    } catch (error) {
      console.error("Error updating organization plan:", error);
    }
  };

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={() => {}}
    >
      <DialogTitle>Choose Your Plan</DialogTitle>
      <DialogContent>
        <PricingPlans onPlanSelect={handlePlanSelect} />
      </DialogContent>
    </Dialog>
  );
};

export default FirstTimeLogin;
