import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Avatar,
  Divider,
} from "@mui/material";
import {
  Dashboard as DashboardIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  Build as BuildIcon,
  Description as DescriptionIcon,
  Engineering as EngineeringIcon,
  AccountCircle,
  Notifications,
  KeyboardArrowDown,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useOrganization } from "../../contexts/OrganizationContext";
import { getDoc, doc, getFirestore } from "firebase/firestore";

const drawerWidth = 280;

const menuItems = [
  { text: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
  { text: "Properties", path: "/dashboard/properties", icon: <BusinessIcon /> },
  { text: "Tenants", path: "/dashboard/tenants", icon: <PeopleIcon /> },
  {
    text: "Maintenance",
    path: "/dashboard/maintenance",
    icon: <BuildIcon />,
  },
  {
    text: "Contractors",
    path: "/dashboard/contractors",
    icon: <EngineeringIcon />,
  },
  {
    text: "Documents",
    path: "/dashboard/documents",
    icon: <DescriptionIcon />,
  },
];

const DashboardLayout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const db = getFirestore();
  const { organization } = useOrganization();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (auth.currentUser) {
        try {
          const userDoc = await getDoc(doc(db, "users", auth.currentUser.uid));
          if (userDoc.exists()) {
            setUserProfile(userDoc.data());
          }
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      }
    };

    fetchUserProfile();
  }, [auth.currentUser, db]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
          bgcolor: "background.paper",
          backdropFilter: "blur(6px)",
          borderBottom: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              color: "text.primary",
              fontWeight: 600,
              fontSize: "1.2rem",
            }}
          >
            {menuItems.find((item) => item.path === location.pathname)?.text ||
              "Dashboard"}
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <IconButton
              size="large"
              sx={{
                background: "rgba(0,0,0,0.04)",
                "&:hover": { background: "rgba(0,0,0,0.08)" },
              }}
            >
              <Notifications />
            </IconButton>
            <Box
              onClick={(e) => setAnchorEl(e.currentTarget)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                padding: "6px 8px",
                borderRadius: 2,
                "&:hover": { bgcolor: "rgba(0,0,0,0.04)" },
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "primary.main",
                  width: 36,
                  height: 36,
                }}
              >
                {userProfile?.name?.[0] || organization?.name?.[0] || (
                  <AccountCircle />
                )}
              </Avatar>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 600, color: "text.primary" }}
                >
                  {userProfile?.name || organization?.name || "User"}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                  {userProfile?.role || "User"}
                </Typography>
              </Box>
              <KeyboardArrowDown sx={{ color: "text.secondary" }} />
            </Box>
          </Box>

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            PaperProps={{
              sx: {
                mt: 1,
                minWidth: 200,
                boxShadow: "0 4px 20px rgba(0,0,0,0.08)",
              },
            }}
          >
            <MenuItem
              onClick={() => {
                navigate("/dashboard/account");
                setAnchorEl(null);
              }}
            >
              Account Settings
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate("/dashboard/billing");
                setAnchorEl(null);
              }}
            >
              Billing & Usage
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "1px solid",
            borderColor: "divider",
            background: "linear-gradient(180deg, #f8f9fa 0%, #ffffff 100%)",
          },
        }}
      >
        <Box
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              backgroundClip: "text",
              textFillColor: "transparent",
              letterSpacing: "-0.5px",
            }}
          >
            {organization?.name || "TenantIO"}
          </Typography>
        </Box>

        <Divider sx={{ mx: 2, mb: 2 }} />

        <List sx={{ px: 2 }}>
          {menuItems.map((item) => (
            <ListItem
              button
              key={item.text}
              onClick={() => navigate(item.path)}
              selected={location.pathname === item.path}
              sx={{
                borderRadius: 2,
                mb: 1,
                "&.Mui-selected": {
                  bgcolor: "primary.lighter",
                  "&:hover": {
                    bgcolor: "primary.lighter",
                  },
                },
                "&:hover": {
                  bgcolor: "rgba(0,0,0,0.04)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  color:
                    location.pathname === item.path
                      ? "primary.main"
                      : "inherit",
                  minWidth: 40,
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                primaryTypographyProps={{
                  fontSize: "0.875rem",
                  fontWeight: location.pathname === item.path ? 600 : 500,
                  color:
                    location.pathname === item.path
                      ? "primary.main"
                      : "text.primary",
                }}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: `calc(100% - ${drawerWidth}px)`,
          minHeight: "100vh",
          bgcolor: "#f8f9fa",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
};

export default DashboardLayout;
