import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";

const TrialCheck = () => {
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const { organization } = useOrganization();
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const checkTrial = async () => {
      if (organization?.id) {
        const orgDoc = await getDoc(doc(db, "organizations", organization.id));
        const orgData = orgDoc.data();

        if (orgData.plan?.status === "trial") {
          const trialEnd = new Date(orgData.plan.trialEndDate);
          const now = new Date();

          if (now > trialEnd) {
            setShowPaymentDialog(true);
          }
        }
      }
    };

    checkTrial();
  }, [organization]);

  const handleClose = () => {
    navigate("/login");
  };

  return (
    <Dialog open={showPaymentDialog} onClose={handleClose}>
      <DialogTitle>Trial Period Expired</DialogTitle>
      <DialogContent>
        <Typography>
          Your 7-day trial period has expired. To continue using the service,
          please upgrade to a paid plan. If no payment is received, your data
          will be deleted within 24 hours.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          variant="contained"
          onClick={() => {
            // Implement payment flow here
            console.log("Implement payment flow");
          }}
        >
          Upgrade Now
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TrialCheck;
