import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { useOrganization } from "../contexts/OrganizationContext";

const OrganizationSetup = ({ open, onClose, user, onComplete }) => {
  const [formData, setFormData] = useState({
    organizationName: "",
    userName: user?.displayName || "",
  });

  const db = getFirestore();
  const { setOrganization } = useOrganization();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const orgData = {
        name: formData.organizationName,
        createdAt: new Date().toISOString(),
        ownerId: user.uid,
        members: [
          {
            userId: user.uid,
            email: user.email,
            name: formData.userName,
            role: "admin",
            joinedAt: new Date().toISOString(),
          },
        ],
      };

      // Create organization document
      await setDoc(doc(db, "organizations", user.uid), orgData);

      // Create user profile
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        name: formData.userName,
        organizationId: user.uid,
        role: "admin",
        createdAt: new Date().toISOString(),
      });

      // Update organization context
      setOrganization({ id: user.uid, ...orgData });

      onComplete();
    } catch (error) {
      console.error("Error setting up organization:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Complete Your Profile</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Please provide some additional information to complete your account
            setup.
          </Typography>
          <TextField
            name="userName"
            label="Your Name"
            fullWidth
            required
            value={formData.userName}
            onChange={handleInputChange}
            sx={{ mb: 2 }}
          />
          <TextField
            name="organizationName"
            label="Organization Name"
            fullWidth
            required
            value={formData.organizationName}
            onChange={handleInputChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!formData.organizationName || !formData.userName}
        >
          Complete Setup
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrganizationSetup;
