import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  Container,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getAuth,
  signInWithEmailAndPassword,
  confirmPasswordReset,
  verifyPasswordResetCode,
} from "firebase/auth";

const TenantPasswordSetup = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const oobCode = urlParams.get("oobCode");

    if (!oobCode) {
      setError("Invalid password reset link");
      setLoading(false);
      return;
    }

    // Verify the action code and get the email
    verifyPasswordResetCode(auth, oobCode)
      .then((email) => {
        setEmail(email);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error verifying code:", error);
        setError("Invalid or expired link");
        setLoading(false);
      });
  }, [location, auth]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const oobCode = new URLSearchParams(location.search).get("oobCode");
      await confirmPasswordReset(auth, oobCode, password);

      // Sign in the user with their new credentials
      await signInWithEmailAndPassword(auth, email, password);

      setSuccess(true);
      // Redirect to onboarding after successful password setup
      setTimeout(() => {
        navigate("/tenant/onboarding");
      }, 2000);
    } catch (error) {
      console.error("Error setting password:", error);
      setError(error.message || "Error setting password");
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ mt: 8, textAlign: "center" }}>
          <Typography>Verifying your link...</Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8 }}>
        <Card>
          <CardContent>
            <Typography
              variant="h5"
              component="h1"
              sx={{ mb: 3, textAlign: "center" }}
            >
              Set Up Your Account
            </Typography>

            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}

            {success ? (
              <Alert severity="success">
                Password set successfully! Redirecting to onboarding...
              </Alert>
            ) : (
              <Box component="form" onSubmit={handleSubmit}>
                <Typography variant="body2" sx={{ mb: 3 }}>
                  Please set a password for your tenant account: {email}
                </Typography>

                <TextField
                  fullWidth
                  type="password"
                  label="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  margin="normal"
                  required
                  autoFocus
                />

                <TextField
                  fullWidth
                  type="password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  margin="normal"
                  required
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3 }}
                  size="large"
                >
                  Create Account
                </Button>
              </Box>
            )}
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default TenantPasswordSetup;
