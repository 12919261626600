import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Tabs,
  Tab,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Business as BusinessIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
  Close as CloseIcon,
  CalendarToday as CalendarIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  orderBy,
  updateDoc,
} from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import FileUpload from "../common/FileUpload";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const Properties = () => {
  const propertyTypes = ["House", "Apartment", "HMO", "Other"];
  const [properties, setProperties] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingProperty, setEditingProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [newProperty, setNewProperty] = useState({
    name: "",
    address: "",
    type: "",
    units: "",
    monthlyRent: "",
    description: "",
  });
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [tenancyDetails, setTenancyDetails] = useState({
    rentDueDate: "",
    tenantId: "",
    leaseStart: "",
    leaseEnd: "",
  });
  const [approvedTenants, setApprovedTenants] = useState([]);

  const { organization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    console.log("Organization context:", organization);
    fetchProperties();
    fetchApprovedTenants();
  }, [organization]);

  const fetchProperties = async () => {
    if (!organization?.id) {
      console.log("No organization ID available");
      return;
    }

    try {
      console.log("Fetching properties for org:", organization.id);
      const q = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const propertiesList = [];
      querySnapshot.forEach((doc) => {
        propertiesList.push({ id: doc.id, ...doc.data() });
      });
      console.log("Fetched properties:", propertiesList);
      setProperties(propertiesList);
    } catch (error) {
      console.error("Error fetching properties:", error);
      setError("Failed to load properties");
    } finally {
      setLoading(false);
    }
  };

  const fetchApprovedTenants = async () => {
    try {
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("approved", "==", true)
      );
      const snapshot = await getDocs(q);
      const tenants = [];
      snapshot.forEach((doc) => {
        tenants.push({ id: doc.id, ...doc.data() });
      });
      setApprovedTenants(tenants);
    } catch (error) {
      console.error("Error fetching approved tenants:", error);
    }
  };

  const handleAddProperty = async () => {
    if (!organization?.id) {
      console.log("No organization ID available for adding property");
      setError("Organization context not available");
      return;
    }

    try {
      // Validate input
      if (!newProperty.name || !newProperty.address) {
        setError("Please fill in all required fields");
        return;
      }

      const propertyData = {
        ...newProperty,
        organizationId: organization.id,
        createdAt: new Date().toISOString(),
        monthlyRent: Number(newProperty.monthlyRent) || 0,
        units: parseInt(newProperty.units, 10) || 0,
      };

      console.log("Adding property:", propertyData);

      await addDoc(collection(db, "properties"), propertyData);

      setNewProperty({
        name: "",
        address: "",
        type: "",
        units: "",
        monthlyRent: "",
        description: "",
      });
      setOpenDialog(false);
      fetchProperties();
    } catch (error) {
      console.error("Error adding property:", error);
      setError(error.message || "Error adding property");
    }
  };

  const handleDeleteProperty = async (propertyId) => {
    if (window.confirm("Are you sure you want to delete this property?")) {
      try {
        await deleteDoc(doc(db, "properties", propertyId));
        fetchProperties();
      } catch (error) {
        console.error("Error deleting property:", error);
        setError("Failed to delete property");
      }
    }
  };

  const handleEdit = (property) => {
    setEditingProperty({
      ...property,
      monthlyRent: property.monthlyRent?.toString(),
      units: property.units?.toString(),
    });
  };

  const handleUpdateProperty = async () => {
    try {
      const propertyData = {
        ...editingProperty,
        monthlyRent: Number(editingProperty.monthlyRent) || 0,
        units: parseInt(editingProperty.units, 10) || 0,
        updatedAt: new Date().toISOString(),
      };

      await updateDoc(doc(db, "properties", editingProperty.id), propertyData);
      setEditingProperty(null);
      fetchProperties();
    } catch (error) {
      console.error("Error updating property:", error);
      setError(error.message || "Error updating property");
    }
  };

  const handleTenancyUpdate = async () => {
    try {
      const propertyRef = doc(db, "properties", selectedProperty.id);
      await updateDoc(propertyRef, {
        tenancy: {
          ...tenancyDetails,
          updatedAt: new Date().toISOString(),
        },
      });

      // Update tenant's property assignment if changed
      if (tenancyDetails.tenantId) {
        const tenantRef = doc(db, "tenants", tenancyDetails.tenantId);
        await updateDoc(tenantRef, {
          propertyId: selectedProperty.id,
          propertyName: selectedProperty.name,
          leaseStart: tenancyDetails.leaseStart,
          leaseEnd: tenancyDetails.leaseEnd,
          rentAmount: Number(selectedProperty.monthlyRent) || 0,
          rentDueDate: Number(tenancyDetails.rentDueDate) || 1,
          status: "active",
          updatedAt: new Date().toISOString(),
        });
      }

      fetchProperties();
      setSnackbar({
        open: true,
        message: "Tenancy details updated successfully",
      });
    } catch (error) {
      console.error("Error updating tenancy:", error);
      setSnackbar({
        open: true,
        message: "Error updating tenancy details",
        severity: "error",
      });
    }
  };

  const renderPropertyCard = (property) => {
    // Calculate months remaining in tenancy if lease end date exists
    const getMonthsRemaining = () => {
      if (!property.tenancy?.leaseEnd) return null;
      const today = new Date();
      const leaseEnd = new Date(property.tenancy.leaseEnd);
      const monthsDiff =
        (leaseEnd.getFullYear() - today.getFullYear()) * 12 +
        (leaseEnd.getMonth() - today.getMonth());
      return monthsDiff;
    };

    const monthsRemaining = getMonthsRemaining();

    // Get color and text based on months remaining
    const getTenancyStatusStyle = (months) => {
      if (months === null) return null;
      if (months <= 3) {
        return {
          color: "error.main",
          bgcolor: "error.lighter",
          text: `${months} months remaining`,
        };
      }
      if (months <= 5) {
        return {
          color: "warning.main",
          bgcolor: "warning.lighter",
          text: `${months} months remaining`,
        };
      }
      if (months <= 12) {
        return {
          color: "success.main",
          bgcolor: "success.lighter",
          text: `${months} months remaining`,
        };
      }
      return {
        color: "info.main",
        bgcolor: "info.lighter",
        text: `${months} months remaining`,
      };
    };

    const tenancyStatus = getTenancyStatusStyle(monthsRemaining);

    return (
      <Card
        key={property.id}
        sx={{
          mb: 2,
          border: "1px solid",
          borderColor: "divider",
          boxShadow: "none",
          "&:hover": {
            borderColor: "primary.main",
            bgcolor: "background.paper",
          },
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            "&:last-child": { pb: 2 },
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            {property.thumbnailUrl ? (
              <Box
                component="img"
                src={property.thumbnailUrl}
                alt={property.name}
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  objectFit: "cover",
                  loading: "lazy",
                }}
                loading="lazy"
              />
            ) : (
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: 2,
                  bgcolor: "primary.lighter",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <BusinessIcon sx={{ color: "primary.main" }} />
              </Box>
            )}
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                {property.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {property.address}
              </Typography>
              <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                <Chip
                  label={`${property.units} Units`}
                  size="small"
                  sx={{ bgcolor: "primary.lighter", color: "primary.main" }}
                />
                <Chip
                  label={`$${property.monthlyRent?.toLocaleString()}/month`}
                  size="small"
                  sx={{ bgcolor: "success.lighter", color: "success.main" }}
                />
                {tenancyStatus && (
                  <Chip
                    label={tenancyStatus.text}
                    size="small"
                    sx={{
                      bgcolor: tenancyStatus.bgcolor,
                      color: tenancyStatus.color,
                      "& .MuiChip-label": {
                        fontWeight: 500,
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setSelectedProperty(property)}
              sx={{ mr: 2 }}
            >
              View
            </Button>
            <IconButton
              size="small"
              sx={{ color: "primary.main" }}
              onClick={() => handleEdit(property)}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              size="small"
              sx={{ color: "error.main" }}
              onClick={() => handleDeleteProperty(property.id)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box>
      <DataList
        items={properties}
        renderItem={renderPropertyCard}
        searchPlaceholder="Search properties..."
        searchField="name"
        pageSize={8}
        filters={[
          { label: "All Properties", value: "all" },
          { label: "Residential", value: "residential" },
          { label: "Commercial", value: "commercial" },
        ]}
        actionButtons={
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            Add Property
          </Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Add New Property</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Property Name"
            fullWidth
            required
            value={newProperty.name}
            onChange={(e) =>
              setNewProperty({ ...newProperty, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Address"
            fullWidth
            required
            value={newProperty.address}
            onChange={(e) =>
              setNewProperty({ ...newProperty, address: e.target.value })
            }
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel>Property Type</InputLabel>
            <Select
              value={newProperty.type || ""}
              onChange={(e) =>
                setNewProperty({ ...newProperty, type: e.target.value })
              }
              label="Property Type"
            >
              {propertyTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Number of Units"
            type="number"
            fullWidth
            value={newProperty.units}
            onChange={(e) =>
              setNewProperty({ ...newProperty, units: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Monthly Rent"
            type="number"
            fullWidth
            value={newProperty.monthlyRent}
            onChange={(e) =>
              setNewProperty({ ...newProperty, monthlyRent: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={newProperty.description}
            onChange={(e) =>
              setNewProperty({ ...newProperty, description: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddProperty} variant="contained">
            Add Property
          </Button>
        </DialogActions>
      </Dialog>

      <PropertyDetailsDialog
        property={selectedProperty}
        onClose={() => setSelectedProperty(null)}
      />

      <Dialog
        open={Boolean(editingProperty)}
        onClose={() => setEditingProperty(null)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Edit Property</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Property Name"
            fullWidth
            required
            value={editingProperty?.name || ""}
            onChange={(e) =>
              setEditingProperty({ ...editingProperty, name: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Address"
            fullWidth
            required
            value={editingProperty?.address || ""}
            onChange={(e) =>
              setEditingProperty({
                ...editingProperty,
                address: e.target.value,
              })
            }
          />
          <FormControl margin="dense" fullWidth>
            <InputLabel>Property Type</InputLabel>
            <Select
              value={editingProperty?.type || ""}
              onChange={(e) =>
                setEditingProperty({ ...editingProperty, type: e.target.value })
              }
              label="Property Type"
            >
              {propertyTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Number of Units"
            type="number"
            fullWidth
            value={editingProperty?.units || ""}
            onChange={(e) =>
              setEditingProperty({ ...editingProperty, units: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Monthly Rent"
            type="number"
            fullWidth
            value={editingProperty?.monthlyRent || ""}
            onChange={(e) =>
              setEditingProperty({
                ...editingProperty,
                monthlyRent: e.target.value,
              })
            }
          />
          <TextField
            margin="dense"
            label="Description"
            fullWidth
            multiline
            rows={3}
            value={editingProperty?.description || ""}
            onChange={(e) =>
              setEditingProperty({
                ...editingProperty,
                description: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingProperty(null)}>Cancel</Button>
          <Button onClick={handleUpdateProperty} variant="contained">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const PropertyDetailsDialog = ({ property, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [tenancyDetails, setTenancyDetails] = useState({
    rentDueDate: "",
    tenantId: "",
    leaseStart: "",
    leaseEnd: "",
  });
  const { organization } = useOrganization();
  const db = getFirestore();
  const queryClient = useQueryClient();

  // Fetch documents query
  const { data: documents = [] } = useQuery({
    queryKey: ["documents", property?.id],
    queryFn: async ({ queryKey }) => {
      const [_, propertyId] = queryKey;
      const q = query(
        collection(db, "files"),
        where("entityId", "==", propertyId),
        where("folder", "==", "property-documents")
      );
      const querySnapshot = await getDocs(q);
      const documentsList = [];
      querySnapshot.forEach((doc) => {
        documentsList.push({ id: doc.id, ...doc.data() });
      });
      return documentsList.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );
    },
    enabled: !!property?.id && activeTab === 1,
  });

  // Fetch approved tenants for tenant selection
  const { data: approvedTenants = [], isLoading: isLoadingTenants } = useQuery({
    queryKey: ["approvedTenants", organization?.id],
    queryFn: async () => {
      if (!organization?.id) {
        console.log("Organization ID not available yet");
        return [];
      }
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("status", "==", "active")
      );
      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    },
    enabled: !!organization?.id,
  });

  // Fetch maintenance requests query
  const { data: maintenanceRequests = [], isLoading: isLoadingRequests } =
    useQuery({
      queryKey: ["maintenanceRequests", property?.id],
      queryFn: async () => {
        if (!property?.id) return [];
        const q = query(
          collection(db, "maintenanceRequests"),
          where("propertyId", "==", property.id),
          orderBy("createdAt", "desc")
        );
        const snapshot = await getDocs(q);
        return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      },
      enabled: !!property?.id && activeTab === 3,
    });

  // Fetch current tenancy details
  useEffect(() => {
    if (property?.tenancy) {
      setTenancyDetails({
        rentDueDate: property.tenancy.rentDueDate || "",
        tenantId: property.tenancy.tenantId || "",
        leaseStart: property.tenancy.leaseStart || "",
        leaseEnd: property.tenancy.leaseEnd || "",
      });
    }
  }, [property]);

  const handleTenancyUpdate = async () => {
    try {
      const propertyRef = doc(db, "properties", property.id);
      await updateDoc(propertyRef, {
        tenancy: {
          ...tenancyDetails,
          updatedAt: new Date().toISOString(),
        },
      });

      // Update tenant's property assignment if changed
      if (tenancyDetails.tenantId) {
        const tenantRef = doc(db, "tenants", tenancyDetails.tenantId);
        await updateDoc(tenantRef, {
          propertyId: property.id,
          propertyName: property.name,
          leaseStart: tenancyDetails.leaseStart,
          leaseEnd: tenancyDetails.leaseEnd,
          rentAmount: Number(property.monthlyRent) || 0,
          rentDueDate: Number(tenancyDetails.rentDueDate) || 1,
          status: "active",
          updatedAt: new Date().toISOString(),
        });
      }

      queryClient.invalidateQueries(["properties"]);
      queryClient.invalidateQueries(["approvedTenants"]);
      onClose();
    } catch (error) {
      console.error("Error updating tenancy:", error);
    }
  };

  const handleUpdateRequestStatus = async (requestId, newStatus) => {
    try {
      await updateDoc(doc(db, "maintenanceRequests", requestId), {
        status: newStatus,
        updatedAt: new Date().toISOString(),
      });
      queryClient.invalidateQueries(["maintenanceRequests", property.id]);
    } catch (error) {
      console.error("Error updating maintenance request:", error);
    }
  };

  if (!property) return null;

  return (
    <Dialog open={Boolean(property)} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <BusinessIcon sx={{ color: "primary.main" }} />
          <Typography variant="h6">{property.name}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
            <Tab label="Photos" />
            <Tab label="Documents" />
            <Tab label="Tenancy" />
            <Tab label="Maintenance" />
          </Tabs>
        </Box>

        {activeTab === 0 && (
          <Box>
            <PhotoGallery propertyId={property.id} />
          </Box>
        )}

        {activeTab === 1 && (
          <Box>
            <FileUpload
              folder="property-documents"
              entityId={property.id}
              multiple={true}
              onUploadComplete={() =>
                queryClient.invalidateQueries(["documents", property.id])
              }
            />
            <DocumentList
              documents={documents}
              onDelete={() =>
                queryClient.invalidateQueries(["documents", property.id])
              }
            />
          </Box>
        )}

        {activeTab === 2 && (
          <Box sx={{ py: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Tenant</InputLabel>
                  <Select
                    value={tenancyDetails.tenantId}
                    onChange={(e) =>
                      setTenancyDetails({
                        ...tenancyDetails,
                        tenantId: e.target.value,
                      })
                    }
                    label="Tenant"
                    disabled={isLoadingTenants}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {approvedTenants.map((tenant) => (
                      <MenuItem key={tenant.id} value={tenant.id}>
                        {tenant.firstName} {tenant.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                  {isLoadingTenants && (
                    <FormHelperText>Loading tenants...</FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Lease Start Date"
                  type="date"
                  value={tenancyDetails.leaseStart}
                  onChange={(e) =>
                    setTenancyDetails({
                      ...tenancyDetails,
                      leaseStart: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Lease End Date"
                  type="date"
                  value={tenancyDetails.leaseEnd}
                  onChange={(e) =>
                    setTenancyDetails({
                      ...tenancyDetails,
                      leaseEnd: e.target.value,
                    })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Rent Due Date"
                  type="number"
                  value={tenancyDetails.rentDueDate}
                  onChange={(e) =>
                    setTenancyDetails({
                      ...tenancyDetails,
                      rentDueDate: e.target.value,
                    })
                  }
                  helperText="Day of the month when rent is due (1-31)"
                  inputProps={{
                    min: 1,
                    max: 31,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    variant="contained"
                    onClick={handleTenancyUpdate}
                    sx={{
                      background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                      "&:hover": {
                        background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                      },
                    }}
                  >
                    Save Tenancy Details
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {activeTab === 3 && (
          <Box sx={{ py: 2 }}>
            {isLoadingRequests ? (
              <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              <List>
                {maintenanceRequests.map((request) => (
                  <ListItem
                    key={request.id}
                    sx={{
                      border: "1px solid",
                      borderColor: "divider",
                      borderRadius: 1,
                      mb: 2,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                        {request.title}
                      </Typography>
                      <Chip
                        label={request.status}
                        color={
                          request.status === "pending"
                            ? "warning"
                            : request.status === "in_progress"
                            ? "info"
                            : request.status === "completed"
                            ? "success"
                            : "default"
                        }
                        size="small"
                      />
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      {request.description}
                    </Typography>
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="caption" color="text.secondary">
                        Submitted:{" "}
                        {new Date(request.createdAt).toLocaleString()}
                      </Typography>
                      {request.images && request.images.length > 0 && (
                        <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                          {request.images.map((image, index) => (
                            <Box
                              key={index}
                              component="img"
                              src={image}
                              alt={`Maintenance request image ${index + 1}`}
                              sx={{
                                width: 80,
                                height: 80,
                                objectFit: "cover",
                                borderRadius: 1,
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(image, "_blank")}
                            />
                          ))}
                        </Box>
                      )}
                    </Box>
                    <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                      <Button
                        size="small"
                        variant={
                          request.status === "in_progress"
                            ? "contained"
                            : "outlined"
                        }
                        color="info"
                        onClick={() =>
                          handleUpdateRequestStatus(request.id, "in_progress")
                        }
                        disabled={request.status === "completed"}
                      >
                        In Progress
                      </Button>
                      <Button
                        size="small"
                        variant={
                          request.status === "completed"
                            ? "contained"
                            : "outlined"
                        }
                        color="success"
                        onClick={() =>
                          handleUpdateRequestStatus(request.id, "completed")
                        }
                        disabled={request.status === "completed"}
                      >
                        Mark Complete
                      </Button>
                    </Box>
                  </ListItem>
                ))}
                {maintenanceRequests.length === 0 && (
                  <Typography
                    color="text.secondary"
                    sx={{ py: 2, textAlign: "center" }}
                  >
                    No maintenance requests found
                  </Typography>
                )}
              </List>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

const PhotoGallery = ({ propertyId }) => {
  const [selectedFolder, setSelectedFolder] = useState("all");
  const [folders, setFolders] = useState([]);
  const [previewPhoto, setPreviewPhoto] = useState(null);
  const [editingPhoto, setEditingPhoto] = useState(null);
  const { organization } = useOrganization();
  const db = getFirestore();
  const storage = getStorage();
  const queryClient = useQueryClient();

  const createThumbnail = async (imageUrl) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      const urlWithCache = `${imageUrl}${
        imageUrl.includes("?") ? "&" : "?"
      }_t=${Date.now()}`;
      img.src = urlWithCache;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 150;
        const scaleFactor = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleFactor;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        const thumbnailUrl = canvas.toDataURL("image/jpeg", 0.6);
        resolve(thumbnailUrl);
      };

      img.onerror = () => {
        console.error("Error loading image for thumbnail");
        resolve(imageUrl);
      };
    });
  };

  const fetchPhotos = async ({ queryKey }) => {
    const [_, propertyId, selectedFolder] = queryKey;
    try {
      let q;
      if (selectedFolder === "all") {
        q = query(
          collection(db, "files"),
          where("entityId", "==", propertyId),
          where("folder", "==", "property-photos")
        );
      } else {
        q = query(
          collection(db, "files"),
          where("entityId", "==", propertyId),
          where("folder", "==", "property-photos"),
          where("photoFolder", "==", selectedFolder)
        );
      }

      const querySnapshot = await getDocs(q);
      const photosList = [];
      querySnapshot.forEach((doc) => {
        photosList.push({ id: doc.id, ...doc.data() });
      });
      photosList.sort(
        (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
      );

      const uniqueFolders = [
        ...new Set(photosList.map((photo) => photo.photoFolder)),
      ].filter(Boolean);
      setFolders(uniqueFolders);
      return photosList;
    } catch (error) {
      console.error("Error fetching photos:", error);
      throw error;
    }
  };

  const handleDelete = async (photo) => {
    if (!window.confirm("Are you sure you want to delete this photo?")) {
      return;
    }

    try {
      const fileRef = ref(storage, photo.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, "files", photo.id));
      // Invalidate the cache to trigger a refetch
      queryClient.invalidateQueries(["photos", propertyId]);
    } catch (error) {
      console.error("Error deleting photo:", error);
    }
  };

  const handleRename = async () => {
    try {
      await updateDoc(doc(db, "files", editingPhoto.id), {
        photoName: editingPhoto.photoName,
        photoFolder: editingPhoto.photoFolder,
      });
      queryClient.invalidateQueries(["photos", propertyId]);
      setEditingPhoto(null);
    } catch (error) {
      console.error("Error updating photo:", error);
    }
  };

  const handleSetAsThumbnail = async () => {
    try {
      const thumbnailUrl = await createThumbnail(editingPhoto.fileUrl);
      await updateDoc(doc(db, "properties", propertyId), {
        thumbnailUrl: thumbnailUrl,
      });
      queryClient.invalidateQueries(["properties"]);
      setEditingPhoto(null);
    } catch (error) {
      console.error("Error setting thumbnail:", error);
    }
  };

  const { data: photos = [], isLoading } = useQuery({
    queryKey: ["photos", propertyId, selectedFolder],
    queryFn: fetchPhotos,
    enabled: !!propertyId,
  });

  return (
    <>
      <Dialog
        open={Boolean(previewPhoto)}
        onClose={() => setPreviewPhoto(null)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent sx={{ p: 0, position: "relative" }}>
          {previewPhoto && (
            <>
              <IconButton
                onClick={() => setPreviewPhoto(null)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  bgcolor: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  "&:hover": {
                    bgcolor: "rgba(0, 0, 0, 0.7)",
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
              <Box
                component="img"
                src={previewPhoto.fileUrl}
                alt={previewPhoto.photoName || previewPhoto.fileName}
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "90vh",
                  objectFit: "contain",
                  display: "block",
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <Box
        sx={{
          mb: 4,
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Filter by Folder</InputLabel>
          <Select
            value={selectedFolder}
            onChange={(e) => setSelectedFolder(e.target.value)}
            label="Filter by Folder"
          >
            <MenuItem value="all">All Photos</MenuItem>
            {folders.map((folder) => (
              <MenuItem key={folder} value={folder}>
                {folder}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FileUpload
          folder="property-photos"
          entityId={propertyId}
          multiple={true}
          isPhoto={true}
          onUploadComplete={() =>
            queryClient.invalidateQueries(["photos", propertyId])
          }
          acceptedFiles="image/*"
        />
      </Box>

      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <List>
          {photos.map((photo) => (
            <ListItem
              key={photo.id}
              sx={{
                border: "1px solid",
                borderColor: "divider",
                borderRadius: 1,
                mb: 1,
              }}
            >
              <ListItemIcon>
                <Box
                  component="img"
                  src={photo.fileUrl}
                  alt={photo.fileName}
                  sx={{
                    width: 80,
                    height: 80,
                    objectFit: "cover",
                    borderRadius: 1,
                    mr: 1,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography variant="subtitle2">
                      {photo.photoName || photo.fileName}
                    </Typography>
                    {photo.photoFolder && (
                      <Chip
                        label={photo.photoFolder}
                        size="small"
                        sx={{ bgcolor: "primary.lighter" }}
                      />
                    )}
                  </Box>
                }
                secondary={
                  <>
                    <Typography variant="caption" color="text.secondary">
                      Added {new Date(photo.uploadDate).toLocaleString()}
                    </Typography>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => setEditingPhoto(photo)}
                  sx={{ mr: 1 }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="preview"
                  onClick={() => setPreviewPhoto(photo)}
                  sx={{ mr: 1 }}
                >
                  <VisibilityIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDelete(photo)}
                  color="error"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {photos.length === 0 && (
            <Typography
              color="text.secondary"
              sx={{ py: 2, textAlign: "center" }}
            >
              No photos uploaded yet
            </Typography>
          )}
        </List>
      )}

      {/* Edit Photo Dialog */}
      <Dialog
        open={Boolean(editingPhoto)}
        onClose={() => setEditingPhoto(null)}
      >
        <DialogTitle>Edit Photo Details</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Photo Name"
            fullWidth
            value={editingPhoto?.photoName || editingPhoto?.fileName || ""}
            onChange={(e) =>
              setEditingPhoto({ ...editingPhoto, photoName: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Folder"
            fullWidth
            value={editingPhoto?.photoFolder || ""}
            onChange={(e) =>
              setEditingPhoto({ ...editingPhoto, photoFolder: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingPhoto(null)}>Cancel</Button>
          <Button onClick={handleSetAsThumbnail} color="secondary">
            Set as Property Thumbnail
          </Button>
          <Button onClick={handleRename} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const DocumentList = ({ documents, onDelete }) => {
  const { organization } = useOrganization();
  const db = getFirestore();
  const [editingDoc, setEditingDoc] = useState(null);
  const queryClient = useQueryClient();

  const handleDownload = (document) => {
    window.open(document.fileUrl, "_blank");
  };

  const handleDelete = async (document) => {
    if (!window.confirm("Are you sure you want to delete this document?")) {
      return;
    }

    try {
      const storage = getStorage();
      const fileRef = ref(storage, document.filePath);
      await deleteObject(fileRef);
      await deleteDoc(doc(db, "files", document.id));
      onDelete();
    } catch (error) {
      console.error("Error deleting document:", error);
    }
  };

  const handleRename = async () => {
    try {
      await updateDoc(doc(db, "files", editingDoc.id), {
        fileName: editingDoc.fileName,
      });
      onDelete(); // Trigger refresh
      setEditingDoc(null);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  return (
    <>
      <Dialog open={Boolean(editingDoc)} onClose={() => setEditingDoc(null)}>
        <DialogTitle>Rename Document</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Document Name"
            fullWidth
            value={editingDoc?.fileName || ""}
            onChange={(e) =>
              setEditingDoc({ ...editingDoc, fileName: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditingDoc(null)}>Cancel</Button>
          <Button onClick={handleRename} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <List>
        {documents.map((document) => (
          <ListItem
            key={document.id}
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1,
              mb: 1,
            }}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText
              primary={document.fileName}
              secondary={new Date(document.uploadDate).toLocaleDateString()}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => setEditingDoc(document)}
                sx={{ mr: 1 }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="download"
                onClick={() => handleDownload(document)}
                sx={{ mr: 1 }}
              >
                <DownloadIcon />
              </IconButton>
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDelete(document)}
                color="error"
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
        {documents.length === 0 && (
          <Typography
            color="text.secondary"
            sx={{ py: 2, textAlign: "center" }}
          >
            No documents uploaded yet
          </Typography>
        )}
      </List>
    </>
  );
};

export default Properties;
