import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  IconButton,
  Chip,
} from "@mui/material";
import {
  Add as AddIcon,
  Download as DownloadIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getAuth } from "firebase/auth";
import TenantLayout from "./TenantLayout";

const documentCategories = [
  { value: "lease", label: "Lease Agreement" },
  { value: "id", label: "Identification" },
  { value: "employment", label: "Employment Proof" },
  { value: "reference", label: "References" },
  { value: "inspection", label: "Move-in Inspection" },
  { value: "other", label: "Other" },
];

const TenantDocuments = () => {
  const [documents, setDocuments] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState("");
  const [newDocument, setNewDocument] = useState({
    title: "",
    category: "",
    description: "",
    file: null,
  });

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    if (auth.currentUser) {
      fetchDocuments();
    }
  }, [auth.currentUser]);

  const fetchDocuments = async () => {
    try {
      const q = query(
        collection(db, "documents"),
        where("tenantId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const documentsList = [];
      querySnapshot.forEach((doc) => {
        documentsList.push({ id: doc.id, ...doc.data() });
      });
      setDocuments(documentsList);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewDocument({ ...newDocument, file });
    }
  };

  const handleUpload = async () => {
    try {
      if (!newDocument.title || !newDocument.category || !newDocument.file) {
        setError("Please fill in all required fields and select a file");
        return;
      }

      const storageRef = ref(
        storage,
        `documents/${auth.currentUser.uid}/${Date.now()}_${
          newDocument.file.name
        }`
      );

      const uploadTask = uploadBytesResumable(storageRef, newDocument.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          setError("Error uploading file");
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          await addDoc(collection(db, "documents"), {
            title: newDocument.title,
            category: newDocument.category,
            description: newDocument.description,
            fileUrl: downloadURL,
            fileName: newDocument.file.name,
            tenantId: auth.currentUser.uid,
            createdAt: new Date().toISOString(),
          });

          setOpenDialog(false);
          setNewDocument({
            title: "",
            category: "",
            description: "",
            file: null,
          });
          setUploadProgress(0);
          fetchDocuments();
        }
      );
    } catch (error) {
      console.error("Error uploading document:", error);
      setError(error.message || "Error uploading document");
    }
  };

  const handleDownload = async (document) => {
    try {
      window.open(document.fileUrl, "_blank");
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const handleDelete = async (document) => {
    if (window.confirm("Are you sure you want to delete this document?")) {
      try {
        // Delete from Storage
        const storageRef = ref(storage, document.fileUrl);
        await deleteObject(storageRef);

        // Delete from Firestore
        await deleteDoc(doc(db, "documents", document.id));
        fetchDocuments();
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    }
  };

  return (
    <TenantLayout>
      <Box>
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Documents
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Upload Document
          </Button>
        </Box>

        <Grid container spacing={3}>
          {documents.map((document) => (
            <Grid item xs={12} sm={6} md={4} key={document.id}>
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {document.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {document.description}
                      </Typography>
                      <Chip
                        label={
                          documentCategories.find(
                            (c) => c.value === document.category
                          )?.label
                        }
                        size="small"
                        color="primary"
                        sx={{ mb: 2 }}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        size="small"
                        onClick={() => handleDownload(document)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(document)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </Box>
                  <Typography variant="caption" color="text.secondary">
                    Uploaded on{" "}
                    {new Date(document.createdAt).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Upload Document</DialogTitle>
          <DialogContent>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <TextField
              margin="dense"
              label="Title"
              fullWidth
              required
              value={newDocument.title}
              onChange={(e) =>
                setNewDocument({ ...newDocument, title: e.target.value })
              }
            />
            <TextField
              select
              margin="dense"
              label="Category"
              fullWidth
              required
              value={newDocument.category}
              onChange={(e) =>
                setNewDocument({ ...newDocument, category: e.target.value })
              }
            >
              {documentCategories.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              multiline
              rows={3}
              value={newDocument.description}
              onChange={(e) =>
                setNewDocument({ ...newDocument, description: e.target.value })
              }
            />
            <Button
              variant="outlined"
              component="label"
              fullWidth
              sx={{ mt: 2 }}
            >
              Select File
              <input
                type="file"
                hidden
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.png,.jpg,.jpeg"
              />
            </Button>
            {newDocument.file && (
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Selected file: {newDocument.file.name}
              </Typography>
            )}
            {uploadProgress > 0 && uploadProgress < 100 && (
              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                Uploading: {Math.round(uploadProgress)}%
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleUpload} variant="contained">
              Upload
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </TenantLayout>
  );
};

export default TenantDocuments;
