import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  LinearProgress,
  Chip,
} from "@mui/material";
import { useOrganization } from "../../contexts/OrganizationContext";
import PricingPlans from "../PricingPlans";
import {
  Business as BusinessIcon,
  People as PeopleIcon,
  Engineering as EngineeringIcon,
  Description as DescriptionIcon,
  Person as PersonIcon,
  ArrowBack,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
} from "firebase/firestore";

const UsageMetric = ({ icon, title, used, limit, color }) => {
  const percentage = (used / limit) * 100;

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
          {icon}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>

        <Box sx={{ mb: 1 }}>
          <Typography variant="body2" color="text.secondary">
            {used} of {limit} used
          </Typography>
          <LinearProgress
            variant="determinate"
            value={Math.min(percentage, 100)}
            sx={{
              mt: 1,
              height: 8,
              borderRadius: 1,
              bgcolor: `${color}.lighter`,
              "& .MuiLinearProgress-bar": {
                bgcolor: `${color}.main`,
              },
            }}
          />
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {Math.max(limit - used, 0)} remaining
        </Typography>
      </CardContent>
    </Card>
  );
};

const Billing = () => {
  const navigate = useNavigate();
  const { organization, setOrganization } = useOrganization();
  const [showUpgrade, setShowUpgrade] = React.useState(false);

  const currentPlan = organization?.plan;
  const limits = currentPlan?.limits || {};

  const [usage, setUsage] = React.useState({
    properties: 0,
    tenants: 0,
    users: 0,
    contractors: 0,
    documents: 0,
  });

  React.useEffect(() => {
    const fetchUsage = async () => {
      if (!organization?.id) return;

      const db = getFirestore();
      const counts = {
        properties: 0,
        tenants: 0,
        users: 0,
        contractors: 0,
        documents: 0,
      };

      try {
        // Fetch properties count
        const propertiesQuery = query(
          collection(db, "properties"),
          where("organizationId", "==", organization.id)
        );
        const propertiesSnapshot = await getDocs(propertiesQuery);
        counts.properties = propertiesSnapshot.size;

        // Fetch tenants count
        const tenantsQuery = query(
          collection(db, "tenants"),
          where("organizationId", "==", organization.id)
        );
        const tenantsSnapshot = await getDocs(tenantsQuery);
        counts.tenants = tenantsSnapshot.size;

        // Fetch users count
        const usersQuery = query(
          collection(db, "users"),
          where("organizationId", "==", organization.id)
        );
        const usersSnapshot = await getDocs(usersQuery);
        counts.users = usersSnapshot.size;

        // Fetch contractors count
        const contractorsQuery = query(
          collection(db, "contractors"),
          where("organizationId", "==", organization.id)
        );
        const contractorsSnapshot = await getDocs(contractorsQuery);
        counts.contractors = contractorsSnapshot.size;

        // Fetch documents count
        const documentsQuery = query(
          collection(db, "documents"),
          where("organizationId", "==", organization.id)
        );
        const documentsSnapshot = await getDocs(documentsQuery);
        counts.documents = documentsSnapshot.size;

        setUsage(counts);
      } catch (error) {
        console.error("Error fetching usage metrics:", error);
      }
    };

    fetchUsage();
  }, [organization]);

  const handlePlanUpdate = async (plan) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, "organizations", organization.id), {
        plan: plan,
      });

      // Update local organization state
      setOrganization((prev) => ({
        ...prev,
        plan: plan,
      }));

      // Show success message
      // You might want to add a success notification here
    } catch (error) {
      console.error("Error updating plan:", error);
      // Show error message
      // You might want to add an error notification here
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4 }}>
        <Button
          startIcon={<ArrowBack />}
          onClick={() => navigate("/dashboard")}
          sx={{ mb: 2 }}
        >
          Return to Dashboard
        </Button>
        <Typography variant="h4" sx={{ mb: 1, fontWeight: 700 }}>
          Billing & Usage
        </Typography>
        <Typography color="text.secondary">
          Manage your subscription and monitor resource usage
        </Typography>
      </Box>

      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ mb: 1 }}>
                Current Plan: {currentPlan?.name}
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  {currentPlan?.status === "trial"
                    ? `Trial ends on ${new Date(
                        currentPlan?.trialEndDate
                      ).toLocaleDateString()}`
                    : "Active subscription"}
                </Typography>
                {currentPlan?.status === "trial" && (
                  <Chip label="Trial" size="small" color="warning" />
                )}
              </Box>
            </Box>
            <Button
              variant="contained"
              onClick={() => setShowUpgrade(true)}
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Upgrade Plan
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Typography variant="h6" sx={{ mb: 3 }}>
        Resource Usage
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<BusinessIcon color="primary" />}
            title="Properties"
            used={usage.properties}
            limit={limits.properties}
            color="primary"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<PeopleIcon color="success" />}
            title="Tenants"
            used={usage.tenants}
            limit={limits.tenants}
            color="success"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<PersonIcon color="info" />}
            title="Users"
            used={usage.users}
            limit={limits.users}
            color="info"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<EngineeringIcon color="warning" />}
            title="Contractors"
            used={usage.contractors}
            limit={limits.contractors}
            color="warning"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <UsageMetric
            icon={<DescriptionIcon color="error" />}
            title="Documents"
            used={usage.documents}
            limit={limits.documents}
            color="error"
          />
        </Grid>
      </Grid>

      {showUpgrade && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0,0,0,0.5)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => setShowUpgrade(false)}
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              borderRadius: 2,
              p: 4,
              maxWidth: "1200px",
              width: "90%",
              maxHeight: "90vh",
              overflow: "auto",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box
              sx={{
                mb: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Upgrade Your Plan</Typography>
              <Button
                onClick={() => setShowUpgrade(false)}
                sx={{ color: "text.secondary" }}
              >
                Close
              </Button>
            </Box>
            <PricingPlans
              onPlanSelect={(plan) => {
                // Handle plan upgrade
                // You might want to add confirmation dialog here
                handlePlanUpdate(plan);
                setShowUpgrade(false);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Billing;
