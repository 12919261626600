import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  TextField,
  Grid,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Alert,
  IconButton,
  Stack,
} from "@mui/material";
import {
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
  Image as ImageIcon,
  VideoLibrary as VideoIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import TenantLayout from "./TenantLayout";
import FileUpload from "../common/FileUpload";

const priorityOptions = [
  { value: "low", label: "Low", color: "#22c55e" },
  { value: "medium", label: "Medium", color: "#f59e0b" },
  { value: "high", label: "High", color: "#ef4444" },
];

const issueTypes = [
  { value: "plumbing", label: "Plumbing" },
  { value: "electrical", label: "Electrical" },
  { value: "heating", label: "Heating/Cooling" },
  { value: "appliance", label: "Appliance" },
  { value: "structural", label: "Structural" },
  { value: "pest", label: "Pest Control" },
  { value: "security", label: "Security" },
  { value: "other", label: "Other" },
];

const TenantMaintenance = () => {
  const [requests, setRequests] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [tenantData, setTenantData] = useState(null);
  const [error, setError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [newRequest, setNewRequest] = useState({
    title: "",
    description: "",
    priority: "low",
    issueType: "",
    mediaFiles: [],
  });

  const auth = getAuth();
  const db = getFirestore();
  const storage = getStorage();

  useEffect(() => {
    if (auth.currentUser) {
      fetchTenantData();
      fetchRequests();
    }
  }, [auth.currentUser]);

  const fetchTenantData = async () => {
    try {
      const tenantDoc = await getDoc(doc(db, "tenants", auth.currentUser.uid));
      if (tenantDoc.exists()) {
        setTenantData(tenantDoc.data());
      }
    } catch (error) {
      console.error("Error fetching tenant data:", error);
    }
  };

  const fetchRequests = async () => {
    try {
      const q = query(
        collection(db, "maintenanceRequests"),
        where("tenantId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const requestsList = [];
      querySnapshot.forEach((doc) => {
        requestsList.push({ id: doc.id, ...doc.data() });
      });
      setRequests(requestsList);
    } catch (error) {
      console.error("Error fetching maintenance requests:", error);
    }
  };

  const handleFileUpload = async (files) => {
    setUploading(true);
    try {
      const uploadPromises = Array.from(files).map(async (file) => {
        const fileRef = ref(
          storage,
          `maintenance-media/${auth.currentUser.uid}/${Date.now()}-${file.name}`
        );
        await uploadBytes(fileRef, file);
        const downloadUrl = await getDownloadURL(fileRef);
        return {
          url: downloadUrl,
          type: file.type.startsWith("image/") ? "image" : "video",
          name: file.name,
        };
      });

      const uploadedFiles = await Promise.all(uploadPromises);
      setNewRequest((prev) => ({
        ...prev,
        mediaFiles: [...prev.mediaFiles, ...uploadedFiles],
      }));
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Failed to upload files");
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveFile = (indexToRemove) => {
    setNewRequest((prev) => ({
      ...prev,
      mediaFiles: prev.mediaFiles.filter((_, index) => index !== indexToRemove),
    }));
  };

  const handleSubmit = async () => {
    try {
      if (
        !newRequest.title ||
        !newRequest.description ||
        !newRequest.issueType
      ) {
        setError("Please fill in all required fields");
        return;
      }

      // Add the maintenance request
      const requestData = {
        ...newRequest,
        tenantId: auth.currentUser.uid,
        propertyId: tenantData.propertyId,
        propertyName: tenantData.propertyName,
        organizationId: tenantData.organizationId,
        unit: tenantData.unit,
        status: "pending",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      const docRef = await addDoc(
        collection(db, "maintenanceRequests"),
        requestData
      );

      // Add to activity log
      await addDoc(collection(db, "activityLog"), {
        type: "maintenance_request",
        requestId: docRef.id,
        status: "pending",
        organizationId: tenantData.organizationId,
        timestamp: serverTimestamp(),
        message: `New maintenance request: ${requestData.title}`,
      });

      setOpenDialog(false);
      setNewRequest({
        title: "",
        description: "",
        priority: "low",
        issueType: "",
        mediaFiles: [],
      });
      fetchRequests();
    } catch (error) {
      console.error("Error submitting maintenance request:", error);
      setError(error.message || "Error submitting request");
    }
  };

  const renderMediaPreview = (file, index) => {
    if (file.type === "image") {
      return (
        <Box
          key={index}
          sx={{
            position: "relative",
            width: 100,
            height: 100,
            borderRadius: 1,
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={file.url}
            alt={file.name}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <IconButton
            size="small"
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              bgcolor: "rgba(0,0,0,0.5)",
              "&:hover": { bgcolor: "rgba(0,0,0,0.7)" },
            }}
            onClick={() => handleRemoveFile(index)}
          >
            <CloseIcon sx={{ color: "white", fontSize: 16 }} />
          </IconButton>
        </Box>
      );
    } else {
      return (
        <Box
          key={index}
          sx={{
            position: "relative",
            width: 100,
            height: 100,
            borderRadius: 1,
            bgcolor: "grey.100",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <VideoIcon sx={{ color: "primary.main", mb: 1 }} />
          <Typography variant="caption" sx={{ px: 1, textAlign: "center" }}>
            {file.name}
          </Typography>
          <IconButton
            size="small"
            sx={{
              position: "absolute",
              top: 4,
              right: 4,
              bgcolor: "rgba(0,0,0,0.5)",
              "&:hover": { bgcolor: "rgba(0,0,0,0.7)" },
            }}
            onClick={() => handleRemoveFile(index)}
          >
            <CloseIcon sx={{ color: "white", fontSize: 16 }} />
          </IconButton>
        </Box>
      );
    }
  };

  return (
    <TenantLayout>
      <Box>
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 700 }}>
            Maintenance Requests
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpenDialog(true)}
            sx={{
              background: "linear-gradient(45deg, #2563eb, #7c3aed)",
              "&:hover": {
                background: "linear-gradient(45deg, #1e40af, #5b21b6)",
              },
            }}
          >
            New Request
          </Button>
        </Box>

        <Grid container spacing={3}>
          {requests.map((request) => (
            <Grid item xs={12} key={request.id}>
              <Card
                sx={{
                  border: "1px solid",
                  borderColor: "divider",
                  "&:hover": {
                    borderColor: "primary.main",
                    boxShadow: 1,
                  },
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        {request.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ mb: 2 }}
                      >
                        {request.description}
                      </Typography>
                      <Stack direction="row" spacing={1}>
                        <Chip
                          label={request.status.toUpperCase()}
                          size="small"
                          color={
                            request.status === "completed"
                              ? "success"
                              : request.status === "in_progress"
                              ? "warning"
                              : "default"
                          }
                        />
                        <Chip
                          label={
                            issueTypes.find(
                              (t) => t.value === request.issueType
                            )?.label || request.issueType
                          }
                          size="small"
                          variant="outlined"
                        />
                        <Chip
                          label={
                            priorityOptions.find(
                              (p) => p.value === request.priority
                            )?.label
                          }
                          size="small"
                          sx={{
                            bgcolor: `${
                              priorityOptions.find(
                                (p) => p.value === request.priority
                              )?.color
                            }15`,
                            color: priorityOptions.find(
                              (p) => p.value === request.priority
                            )?.color,
                          }}
                        />
                      </Stack>
                      {request.mediaFiles && request.mediaFiles.length > 0 && (
                        <Box
                          sx={{
                            mt: 2,
                            display: "flex",
                            gap: 1,
                            flexWrap: "wrap",
                          }}
                        >
                          {request.mediaFiles.map((file, index) => (
                            <Box
                              key={index}
                              sx={{
                                width: 80,
                                height: 80,
                                borderRadius: 1,
                                overflow: "hidden",
                                cursor: "pointer",
                              }}
                              onClick={() => window.open(file.url, "_blank")}
                            >
                              {file.type === "image" ? (
                                <Box
                                  component="img"
                                  src={file.url}
                                  alt={`Attachment ${index + 1}`}
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    bgcolor: "grey.100",
                                  }}
                                >
                                  <VideoIcon sx={{ color: "primary.main" }} />
                                </Box>
                              )}
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>
                    <Typography variant="caption" color="text.secondary">
                      {new Date(request.createdAt).toLocaleDateString()}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>New Maintenance Request</DialogTitle>
          <DialogContent>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <TextField
              select
              margin="dense"
              label="Issue Type"
              fullWidth
              required
              value={newRequest.issueType}
              onChange={(e) =>
                setNewRequest({ ...newRequest, issueType: e.target.value })
              }
            >
              {issueTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              margin="dense"
              label="Title"
              fullWidth
              required
              value={newRequest.title}
              onChange={(e) =>
                setNewRequest({ ...newRequest, title: e.target.value })
              }
            />
            <TextField
              margin="dense"
              label="Description"
              fullWidth
              required
              multiline
              rows={4}
              value={newRequest.description}
              onChange={(e) =>
                setNewRequest({ ...newRequest, description: e.target.value })
              }
            />
            <TextField
              select
              margin="dense"
              label="Priority"
              fullWidth
              value={newRequest.priority}
              onChange={(e) =>
                setNewRequest({ ...newRequest, priority: e.target.value })
              }
            >
              {priorityOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            <Box sx={{ mt: 2 }}>
              <input
                accept="image/*,video/*"
                style={{ display: "none" }}
                id="media-file-input"
                multiple
                type="file"
                onChange={(e) => handleFileUpload(e.target.files)}
              />
              <label htmlFor="media-file-input">
                <Button
                  component="span"
                  variant="outlined"
                  startIcon={<CloudUploadIcon />}
                  disabled={uploading}
                >
                  {uploading ? "Uploading..." : "Upload Photos/Videos"}
                </Button>
              </label>
            </Box>

            {newRequest.mediaFiles.length > 0 && (
              <Box sx={{ mt: 2, display: "flex", gap: 1, flexWrap: "wrap" }}>
                {newRequest.mediaFiles.map((file, index) =>
                  renderMediaPreview(file, index)
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              disabled={uploading}
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Submit Request
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </TenantLayout>
  );
};

export default TenantMaintenance;
