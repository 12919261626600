import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Avatar,
  Chip,
  Alert,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ImageList,
  ImageListItem,
} from "@mui/material";
import {
  Business as BusinessIcon,
  People as PeopleIcon,
  Build as BuildIcon,
  Engineering as EngineeringIcon,
  Assignment as AssignmentIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Warning as WarningIcon,
  Person as PersonIcon,
  VideoLibrary as VideoIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import FirstTimeLogin from "../FirstTimeLogin";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    properties: 0,
    tenants: 0,
    maintenance: 0,
    contractors: 0,
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [pendingMaintenance, setPendingMaintenance] = useState([]);
  const [pendingApplications, setPendingApplications] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);

  const { organization, isLoading: orgLoading } = useOrganization();
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    if (organization?.id) {
      fetchStats();
      fetchRecentActivity();
      fetchPendingMaintenance();
      fetchPendingApplications();
    }
  }, [organization]);

  const fetchStats = async () => {
    try {
      // Get properties count
      const propertiesQuery = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const propertiesSnapshot = await getDocs(propertiesQuery);

      // Get tenants count
      const tenantsQuery = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id)
      );
      const tenantsSnapshot = await getDocs(tenantsQuery);

      // Get pending maintenance requests count
      const maintenanceQuery = query(
        collection(db, "maintenanceRequests"),
        where("organizationId", "==", organization.id),
        where("status", "in", ["pending", "in_progress"])
      );
      const maintenanceSnapshot = await getDocs(maintenanceQuery);

      // Get contractors count
      const contractorsQuery = query(
        collection(db, "contractors"),
        where("organizationId", "==", organization.id)
      );
      const contractorsSnapshot = await getDocs(contractorsQuery);

      setStats({
        properties: propertiesSnapshot.size,
        tenants: tenantsSnapshot.size,
        maintenance: maintenanceSnapshot.size,
        contractors: contractorsSnapshot.size,
      });
    } catch (error) {
      console.error("Error fetching stats:", error);
    }
  };

  const fetchRecentActivity = async () => {
    try {
      const maintenanceRef = collection(db, "maintenanceRequests");
      const q = query(
        maintenanceRef,
        where("organizationId", "==", organization.id),
        orderBy("createdAt", "desc"),
        limit(5)
      );

      const snapshot = await getDocs(q);
      const activity = snapshot.docs.map((doc) => ({
        id: doc.id,
        type: "maintenance",
        timestamp: doc.data().createdAt,
        ...doc.data(),
      }));

      setRecentActivity(activity);
    } catch (error) {
      console.error("Error fetching recent activity:", error);
    }
  };

  const fetchPendingMaintenance = async () => {
    try {
      const pendingQuery = query(
        collection(db, "maintenanceRequests"),
        where("organizationId", "==", organization.id),
        where("status", "in", ["pending", "in_progress"]),
        limit(10)
      );

      const snapshot = await getDocs(pendingQuery);
      const requests = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        statusOrder: doc.data().status === "pending" ? 1 : 2,
      }));

      // Sort in memory
      const sortedRequests = requests
        .sort((a, b) => {
          // Sort by priority first
          const priorityOrder = { high: 1, medium: 2, low: 3 };
          const priorityDiff =
            priorityOrder[a.priority] - priorityOrder[b.priority];
          if (priorityDiff !== 0) return priorityDiff;

          // Then by status
          const statusDiff = a.statusOrder - b.statusOrder;
          if (statusDiff !== 0) return statusDiff;

          // Finally by date
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .slice(0, 5);

      setPendingMaintenance(sortedRequests);
    } catch (error) {
      console.error("Error fetching pending maintenance:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPendingApplications = async () => {
    if (organization?.id) {
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id),
        where("status", "==", "pending_approval")
      );

      const snapshot = await getDocs(q);
      const applications = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPendingApplications(applications);
    }
  };

  const getActivityIcon = (activity) => {
    switch (activity.type) {
      case "maintenance":
        return activity.status === "completed" ? (
          <CheckCircleIcon color="success" />
        ) : (
          <BuildIcon color="primary" />
        );
      case "tenant":
        return <PeopleIcon color="primary" />;
      default:
        return <AssignmentIcon />;
    }
  };

  const getActivityText = (activity) => {
    switch (activity.type) {
      case "maintenance":
        return {
          primary: activity.title,
          secondary: `${activity.propertyName} - Status: ${activity.status}`,
        };
      case "tenant":
        return {
          primary: `${activity.firstName} ${activity.lastName}`,
          secondary: `New tenant added to ${activity.propertyName}`,
        };
      default:
        return {
          primary: "Activity",
          secondary: new Date(activity.createdAt).toLocaleDateString(),
        };
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "high":
        return "error";
      case "medium":
        return "warning";
      case "low":
        return "success";
      default:
        return "info";
    }
  };

  const handleViewRequest = (request) => {
    setSelectedRequest(request);
    setViewDialogOpen(true);
  };

  if (loading || orgLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!organization) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>No organization found. Please contact support.</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <FirstTimeLogin />
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              bgcolor: "primary.lighter",
              height: "100%",
              transition: "transform 0.2s",
              "&:hover": { transform: "translateY(-4px)" },
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: "primary.main" }}>
                  <BusinessIcon />
                </Avatar>
                <Box>
                  <Typography
                    color="primary.main"
                    variant="h4"
                    fontWeight="bold"
                  >
                    {stats.properties}
                  </Typography>
                  <Typography color="text.secondary">Properties</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              bgcolor: "success.lighter",
              height: "100%",
              transition: "transform 0.2s",
              "&:hover": { transform: "translateY(-4px)" },
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: "success.main" }}>
                  <PeopleIcon />
                </Avatar>
                <Box>
                  <Typography
                    color="success.main"
                    variant="h4"
                    fontWeight="bold"
                  >
                    {stats.tenants}
                  </Typography>
                  <Typography color="text.secondary">Tenants</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              bgcolor:
                stats.maintenance > 0 ? "warning.lighter" : "warning.lighter",
              height: "100%",
              transition: "transform 0.2s",
              "&:hover": { transform: "translateY(-4px)" },
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar
                  sx={{
                    bgcolor:
                      stats.maintenance > 0 ? "warning.main" : "warning.main",
                  }}
                >
                  <BuildIcon />
                </Avatar>
                <Box>
                  <Typography
                    color={
                      stats.maintenance > 0 ? "warning.main" : "warning.main"
                    }
                    variant="h4"
                    fontWeight="bold"
                  >
                    {stats.maintenance}
                  </Typography>
                  <Typography color="text.secondary">
                    {stats.maintenance === 1
                      ? "Pending Request"
                      : "Pending Requests"}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Card
            sx={{
              bgcolor: "info.lighter",
              height: "100%",
              transition: "transform 0.2s",
              "&:hover": { transform: "translateY(-4px)" },
            }}
          >
            <CardContent>
              <Box display="flex" alignItems="center" gap={2}>
                <Avatar sx={{ bgcolor: "info.main" }}>
                  <EngineeringIcon />
                </Avatar>
                <Box>
                  <Typography color="info.main" variant="h4" fontWeight="bold">
                    {stats.contractors}
                  </Typography>
                  <Typography color="text.secondary">Contractors</Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Recent Activity
              </Typography>
              <Box sx={{ minHeight: 400 }}>
                <List>
                  {recentActivity.map((activity) => (
                    <ListItem key={activity.id}>
                      <ListItemIcon>
                        <BuildIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={`New maintenance request: ${activity.title}`}
                        secondary={
                          activity.timestamp
                            ? new Date(activity.timestamp).toLocaleDateString()
                            : "Date not available"
                        }
                      />
                    </ListItem>
                  ))}
                  {recentActivity.length === 0 && (
                    <Box
                      sx={{
                        py: 8,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography color="text.secondary">
                        No recent activity
                      </Typography>
                    </Box>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
                Pending Maintenance
              </Typography>
              <Box sx={{ minHeight: 400 }}>
                <List>
                  {pendingMaintenance.length > 0 ? (
                    pendingMaintenance.map((request) => (
                      <React.Fragment key={request.id}>
                        <ListItem
                          sx={{
                            borderRadius: 1,
                            cursor: "pointer",
                            "&:hover": {
                              bgcolor: "action.hover",
                            },
                          }}
                          onClick={() => handleViewRequest(request)}
                        >
                          <ListItemIcon>
                            {request.priority === "high" ? (
                              <WarningIcon color="error" />
                            ) : (
                              <ScheduleIcon
                                color={getPriorityColor(request.priority)}
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                {request.title}
                                <Chip
                                  label={request.priority}
                                  size="small"
                                  sx={{
                                    bgcolor: `${getPriorityColor(
                                      request.priority
                                    )}.lighter`,
                                    color: `${getPriorityColor(
                                      request.priority
                                    )}.main`,
                                    fontSize: "0.75rem",
                                  }}
                                />
                              </Box>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                {request.propertyName}
                                {request.unit && ` - Unit ${request.unit}`}
                                {" • "}
                                {new Date(
                                  request.createdAt
                                ).toLocaleDateString()}
                              </Typography>
                            }
                          />
                          <Typography
                            variant="caption"
                            sx={{
                              px: 1.5,
                              py: 0.75,
                              borderRadius: 1,
                              bgcolor:
                                request.status === "pending"
                                  ? "warning.lighter"
                                  : "info.lighter",
                              color:
                                request.status === "pending"
                                  ? "warning.main"
                                  : "info.main",
                              fontWeight: 500,
                            }}
                          >
                            {request.status === "pending"
                              ? "Pending"
                              : "In Progress"}
                          </Typography>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))
                  ) : (
                    <Box
                      sx={{
                        py: 8,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography color="text.secondary">
                        No pending maintenance requests
                      </Typography>
                    </Box>
                  )}
                </List>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {pendingApplications.length > 0 && (
        <Box sx={{ mb: 4 }}>
          <Alert
            severity="info"
            sx={{ mb: 2 }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={() => navigate("/dashboard/tenants")}
              >
                View All
              </Button>
            }
          >
            You have {pendingApplications.length} pending tenant application(s)
          </Alert>

          <Grid container spacing={2}>
            {pendingApplications.map((application) => (
              <Grid item xs={12} md={6} key={application.id}>
                <Card>
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 2,
                      }}
                    >
                      <Typography variant="h6">
                        {application.firstName} {application.lastName}
                      </Typography>
                      <Chip
                        label="Pending Review"
                        color="warning"
                        size="small"
                      />
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      Property: {application.propertyName}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      paragraph
                    >
                      Submitted:{" "}
                      {new Date(application.updatedAt).toLocaleDateString()}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() =>
                        navigate(`/dashboard/tenants/review/${application.id}`)
                      }
                    >
                      Review Application
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Maintenance Request Details Dialog */}
      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedRequest && (
          <>
            <DialogTitle>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6">{selectedRequest.title}</Typography>
                <Chip
                  label={selectedRequest.status.toUpperCase()}
                  color={
                    selectedRequest.status === "completed"
                      ? "success"
                      : selectedRequest.status === "in_progress"
                      ? "warning"
                      : "default"
                  }
                  size="small"
                />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Property
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedRequest.propertyName}
                    {selectedRequest.unit && ` - Unit ${selectedRequest.unit}`}
                  </Typography>

                  <Typography variant="subtitle2" color="text.secondary">
                    Issue Type
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedRequest.issueType}
                  </Typography>

                  <Typography variant="subtitle2" color="text.secondary">
                    Priority
                  </Typography>
                  <Chip
                    label={selectedRequest.priority.toUpperCase()}
                    color={getPriorityColor(selectedRequest.priority)}
                    size="small"
                    sx={{ mb: 2 }}
                  />

                  <Typography variant="subtitle2" color="text.secondary">
                    Submitted
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {new Date(selectedRequest.createdAt).toLocaleString()}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle2" color="text.secondary">
                    Description
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 2 }}>
                    {selectedRequest.description}
                  </Typography>
                </Grid>

                {selectedRequest.mediaFiles &&
                  selectedRequest.mediaFiles.length > 0 && (
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ mb: 1 }}
                      >
                        Attachments
                      </Typography>
                      <ImageList cols={3} gap={8}>
                        {selectedRequest.mediaFiles.map((file, index) => (
                          <ImageListItem
                            key={index}
                            sx={{
                              cursor: "pointer",
                              "&:hover": { opacity: 0.8 },
                            }}
                            onClick={() => window.open(file.url, "_blank")}
                          >
                            {file.type === "image" ? (
                              <img
                                src={file.url}
                                alt={`Attachment ${index + 1}`}
                                loading="lazy"
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: "4px",
                                }}
                              />
                            ) : (
                              <Box
                                sx={{
                                  width: "100%",
                                  height: "200px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  bgcolor: "grey.100",
                                  borderRadius: 1,
                                }}
                              >
                                <VideoIcon
                                  sx={{ fontSize: 48, color: "primary.main" }}
                                />
                              </Box>
                            )}
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Grid>
                  )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

export default Dashboard;
