import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Alert,
  Chip,
  IconButton,
  Card,
  CardContent,
  Avatar,
  Grid,
  Tabs,
  Tab,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
} from "@mui/icons-material";
import {
  getFirestore,
  collection,
  addDoc,
  query,
  where,
  getDocs,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { useOrganization } from "../../contexts/OrganizationContext";
import DataList from "../common/DataList";
import FileUpload from "../common/FileUpload";
import { sendPasswordResetEmail } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const statusOptions = [
  { value: "active", label: "Active", color: "#22c55e" },
  { value: "inactive", label: "Inactive", color: "#ef4444" },
];

const Tenants = () => {
  const [tenants, setTenants] = useState([]);
  const [properties, setProperties] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [editingTenant, setEditingTenant] = useState(null);
  const [newTenant, setNewTenant] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    propertyId: "",
    unit: "",
    status: "active",
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [availableUnits, setAvailableUnits] = useState([]);
  const [success, setSuccess] = useState("");

  const navigate = useNavigate();
  const { organization } = useOrganization();
  const db = getFirestore();

  useEffect(() => {
    if (organization?.id) {
      fetchProperties();
      fetchTenants();
    }
  }, [organization]);

  const fetchProperties = async () => {
    try {
      const q = query(
        collection(db, "properties"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const propertiesList = [];
      querySnapshot.forEach((doc) => {
        propertiesList.push({ id: doc.id, ...doc.data() });
      });
      setProperties(propertiesList);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const fetchTenants = async () => {
    try {
      const q = query(
        collection(db, "tenants"),
        where("organizationId", "==", organization.id)
      );
      const querySnapshot = await getDocs(q);
      const tenantsList = [];
      querySnapshot.forEach((doc) => {
        tenantsList.push({ id: doc.id, ...doc.data() });
      });
      setTenants(tenantsList);
    } catch (error) {
      console.error("Error fetching tenants:", error);
      setError("Failed to load tenants");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setError("");
    setNewTenant({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      propertyId: "",
      unit: "",
    });
  };

  const handleAddTenant = async () => {
    try {
      if (
        !newTenant.firstName ||
        !newTenant.lastName ||
        !newTenant.email ||
        !newTenant.propertyId ||
        !newTenant.unit
      ) {
        setError("Please fill in all required fields");
        return;
      }

      const tempPassword = Math.random().toString(36).slice(-8);
      const auth = getAuth();
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newTenant.email,
        tempPassword
      );

      const propertyDetails = properties.find(
        (p) => p.id === newTenant.propertyId
      );

      // Create tenant document
      await setDoc(doc(db, "tenants", userCredential.user.uid), {
        firstName: newTenant.firstName,
        lastName: newTenant.lastName,
        email: newTenant.email,
        phone: newTenant.phone,
        propertyId: newTenant.propertyId,
        propertyName: propertyDetails?.name || "",
        unit: newTenant.unit,
        organizationId: organization.id,
        status: "pending_onboarding",
        onboardingComplete: false,
        createdAt: new Date().toISOString(),
        uid: userCredential.user.uid,
      });

      // Create user profile document
      await setDoc(doc(db, "users", userCredential.user.uid), {
        firstName: newTenant.firstName,
        lastName: newTenant.lastName,
        email: newTenant.email,
        role: "tenant",
        organizationId: organization.id,
        createdAt: new Date().toISOString(),
        type: "tenant",
      });

      await sendPasswordResetEmail(auth, newTenant.email, {
        url: `${window.location.origin}/login`,
        handleCodeInApp: true,
      });

      setOpenDialog(false);
      fetchTenants();
      setSuccess(
        "Tenant added successfully. A password setup email has been sent."
      );
    } catch (error) {
      console.error("Error adding tenant:", error);
      setError(error.message || "Error adding tenant");
    }
  };

  const handleDeleteTenant = async (tenantId) => {
    if (window.confirm("Are you sure you want to delete this tenant?")) {
      try {
        await deleteDoc(doc(db, "tenants", tenantId));
        fetchTenants();
      } catch (error) {
        console.error("Error deleting tenant:", error);
        setError("Failed to delete tenant");
      }
    }
  };

  const handleViewTenant = async (tenant) => {
    try {
      const tenantDoc = await getDoc(doc(db, "tenants", tenant.id));
      setSelectedTenant({ id: tenantDoc.id, ...tenantDoc.data() });
      setViewDialogOpen(true);
    } catch (error) {
      console.error("Error fetching tenant details:", error);
    }
  };

  const handleEdit = (tenant) => {
    setEditingTenant(tenant);
    setOpenDialog(true);
  };

  const handleUpdateTenant = async () => {
    try {
      if (!editingTenant.unit) {
        setError("Unit is required");
        return;
      }

      const tenantRef = doc(db, "tenants", editingTenant.id);
      const propertyDetails = properties.find(
        (p) => p.id === editingTenant.propertyId
      );

      await updateDoc(tenantRef, {
        firstName: editingTenant.firstName,
        lastName: editingTenant.lastName,
        email: editingTenant.email,
        phone: editingTenant.phone,
        propertyId: editingTenant.propertyId,
        propertyName: propertyDetails?.name || "",
        unit: editingTenant.unit,
        updatedAt: new Date().toISOString(),
      });

      setOpenDialog(false);
      setEditingTenant(null);
      fetchTenants();
    } catch (error) {
      console.error("Error updating tenant:", error);
      setError(error.message || "Error updating tenant");
    }
  };

  const handlePropertyChange = (propertyId) => {
    const selectedProperty = properties.find((p) => p.id === propertyId);
    if (selectedProperty) {
      const units = Array.from({ length: selectedProperty.units }, (_, i) => ({
        value: (i + 1).toString(),
        label: `Unit ${i + 1}`,
      }));
      setAvailableUnits(units);
    } else {
      setAvailableUnits([]);
    }

    if (editingTenant) {
      setEditingTenant({
        ...editingTenant,
        propertyId,
        unit: "",
      });
    } else {
      setNewTenant({
        ...newTenant,
        propertyId,
        unit: "",
      });
    }
  };

  const renderTenantDetails = () => {
    if (!selectedTenant) return null;

    return (
      <Dialog
        open={viewDialogOpen}
        onClose={() => setViewDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar sx={{ bgcolor: "primary.main" }}>
              {selectedTenant.firstName?.[0]}
            </Avatar>
            <Typography variant="h6">
              {selectedTenant.firstName} {selectedTenant.lastName}
            </Typography>
            {selectedTenant.status === "pending_approval" && (
              <Chip
                label="Pending Approval"
                color="warning"
                size="small"
                sx={{ ml: "auto" }}
              />
            )}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            sx={{ mb: 3 }}
          >
            <Tab label="Details" />
            <Tab label="Application" />
            <Tab label="Documents" />
          </Tabs>

          {selectedTab === 0 && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Contact Information
                </Typography>
                <Typography>Email: {selectedTenant.email}</Typography>
                <Typography>Phone: {selectedTenant.phone}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" color="text.secondary">
                  Property Details
                </Typography>
                <Typography>Property: {selectedTenant.propertyName}</Typography>
                <Typography>Unit: {selectedTenant.unit}</Typography>
                <Typography>
                  Rent: ${selectedTenant.rentAmount?.toLocaleString()}
                </Typography>
              </Grid>
            </Grid>
          )}

          {selectedTab === 1 && selectedTenant.onboardingComplete && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Personal Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2">Date of Birth</Typography>
                  <Typography>
                    {selectedTenant.personalInfo?.dateOfBirth}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2">
                    National Insurance
                  </Typography>
                  <Typography>
                    {selectedTenant.personalInfo?.nationalInsurance}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography variant="subtitle2">Current Income</Typography>
                  <Typography>
                    ${selectedTenant.personalInfo?.currentIncome}
                  </Typography>
                </Grid>
              </Grid>

              <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                Employment History
              </Typography>
              {selectedTenant.employmentHistory?.map((employment, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  <Typography variant="subtitle1">
                    {employment.employer}
                  </Typography>
                  <Typography variant="body2">
                    Position: {employment.position}
                  </Typography>
                  <Typography variant="body2">
                    Duration: {employment.startDate} - {employment.endDate}
                  </Typography>
                  <Typography variant="body2">
                    Contact: {employment.contactPerson} (
                    {employment.contactPhone})
                  </Typography>
                </Box>
              ))}
            </Box>
          )}

          {selectedTab === 2 && (
            <Box>
              {selectedTenant.documents?.map((doc, index) => (
                <Button
                  key={index}
                  href={doc.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  startIcon={<AssignmentIcon />}
                  sx={{ mb: 1, display: "block" }}
                >
                  {doc.name}
                </Button>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          {selectedTenant.status === "pending_approval" && (
            <>
              <Button
                variant="outlined"
                color="error"
                onClick={() =>
                  navigate(`/dashboard/tenants/review/${selectedTenant.id}`)
                }
              >
                Review Application
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  navigate(`/dashboard/tenants/review/${selectedTenant.id}`)
                }
              >
                Make Decision
              </Button>
            </>
          )}
          <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (loading) return null;

  return (
    <Box>
      <Box sx={{ mb: 4 }}>
        <DataList
          items={tenants}
          renderItem={(tenant) => (
            <Card
              key={tenant.id}
              sx={{
                mb: 2,
                "&:hover": {
                  boxShadow: (theme) => theme.shadows[4],
                  transform: "translateY(-2px)",
                  transition: "all 0.3s ease-in-out",
                },
              }}
            >
              <CardContent sx={{ p: 0 }}>
                <ListItem
                  sx={{ py: 2 }}
                  secondaryAction={
                    <Box>
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => handleViewTenant(tenant)}
                        sx={{ mr: 2 }}
                      >
                        View
                      </Button>
                      <IconButton onClick={() => handleEdit(tenant)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteTenant(tenant.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  }
                >
                  <Box>
                    <ListItemText
                      primary={`${tenant.firstName} ${tenant.lastName}`}
                      secondary={
                        tenant.propertyName
                          ? `${tenant.propertyName}${
                              tenant.unit ? ` - Unit ${tenant.unit}` : ""
                            }`
                          : "No property assigned"
                      }
                    />
                    {tenant.onboardingComplete && !tenant.reviewed && (
                      <Box
                        role="alert"
                        className="info-alert"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          mt: 1,
                          p: 1,
                          borderRadius: 1,
                          bgcolor: "info.lighter",
                          borderLeft: "4px solid",
                          borderLeftColor: "info.main",
                          transition: "all 0.3s ease-in-out",
                          "&:hover": {
                            transform: "translateX(4px)",
                            bgcolor: "info.light",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            p: 0.5,
                            borderRadius: 1,
                            bgcolor: "info.main",
                            color: "white",
                          }}
                        >
                          <AssignmentIcon sx={{ fontSize: 18 }} />
                        </Box>
                        <Box>
                          <Typography
                            variant="caption"
                            sx={{
                              fontWeight: 600,
                              color: "info.dark",
                              lineHeight: 1,
                              display: "block",
                            }}
                          >
                            Application Ready
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "info.main",
                              fontSize: "0.7rem",
                            }}
                          >
                            Click to review tenant application
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {tenant.status === "pending_approval" && (
                    <Chip
                      label="Pending Approval"
                      color="warning"
                      size="small"
                      sx={{ ml: 2 }}
                    />
                  )}
                </ListItem>
              </CardContent>
            </Card>
          )}
          searchPlaceholder="Search tenants..."
          searchField="firstName"
          pageSize={8}
          filters={[
            { label: "All Tenants", value: "all" },
            { label: "Active", value: "active" },
            { label: "Inactive", value: "inactive" },
          ]}
          actionButtons={
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleOpenDialog}
              sx={{
                background: "linear-gradient(45deg, #2563eb, #7c3aed)",
                "&:hover": {
                  background: "linear-gradient(45deg, #1e40af, #5b21b6)",
                },
              }}
            >
              Add Tenant
            </Button>
          }
        />
      </Box>

      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
          setEditingTenant(null);
          setError("");
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingTenant ? "Edit Tenant" : "Add New Tenant"}
        </DialogTitle>
        <DialogContent>
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="First Name"
                fullWidth
                required
                value={
                  editingTenant ? editingTenant.firstName : newTenant.firstName
                }
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        firstName: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, firstName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                required
                value={
                  editingTenant ? editingTenant.lastName : newTenant.lastName
                }
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        lastName: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, lastName: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                required
                value={editingTenant ? editingTenant.email : newTenant.email}
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        email: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, email: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                label="Phone"
                fullWidth
                required
                value={editingTenant ? editingTenant.phone : newTenant.phone}
                onChange={(e) =>
                  editingTenant
                    ? setEditingTenant({
                        ...editingTenant,
                        phone: e.target.value,
                      })
                    : setNewTenant({ ...newTenant, phone: e.target.value })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Property</InputLabel>
                <Select
                  value={
                    editingTenant
                      ? editingTenant.propertyId
                      : newTenant.propertyId
                  }
                  onChange={(e) => handlePropertyChange(e.target.value)}
                  label="Property"
                  required
                >
                  {properties.map((property) => (
                    <MenuItem key={property.id} value={property.id}>
                      {property.name} ({property.units} units)
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Unit</InputLabel>
                <Select
                  value={editingTenant ? editingTenant.unit : newTenant.unit}
                  onChange={(e) =>
                    editingTenant
                      ? setEditingTenant({
                          ...editingTenant,
                          unit: e.target.value,
                        })
                      : setNewTenant({
                          ...newTenant,
                          unit: e.target.value,
                        })
                  }
                  label="Unit"
                  required
                  error={Boolean(
                    error && (!editingTenant?.unit || !newTenant.unit)
                  )}
                  disabled={!availableUnits.length}
                >
                  {availableUnits.map((unit) => (
                    <MenuItem key={unit.value} value={unit.value}>
                      {unit.label}
                    </MenuItem>
                  ))}
                </Select>
                {error && (!editingTenant?.unit || !newTenant.unit) && (
                  <FormHelperText error>Unit is required</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setEditingTenant(null);
              setError("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={editingTenant ? handleUpdateTenant : handleAddTenant}
            variant="contained"
          >
            {editingTenant ? "Save Changes" : "Add Tenant"}
          </Button>
        </DialogActions>
      </Dialog>

      {renderTenantDetails()}
    </Box>
  );
};

export default Tenants;
